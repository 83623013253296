<template>
  <div class="container">
    <div class="choose_parent">
      <div class="default_title">{{ $t("weHelp") }}</div>
      <div class="choose_body_parent">
        <div data-aos="fade-up" class="choose_col_left choose_col">
          <div class="choose_body">
            <img
              src="@/assets/images/choose/student.png"
              alt="student"
              draggable="false"
              title="student"
            />
            <div class="choose_title">{{ $t("students") }}</div>
          </div>
          <div class="choose_description">
            {{ $t("toFindIdealTeacher") }}
          </div>
        </div>
        <div
          data-aos-delay="200"
          data-aos="fade-up"
          class="choose_col_right choose_col"
        >
          <div class="choose_body">
            <img
              src="@/assets/images/choose/teacher.png"
              alt="student"
              title="teacher"
              draggable="false"
            />
            <div class="choose_title">{{ $t("teachers") }}</div>
          </div>
          <div class="choose_description">
            {{ $t("toDevelop") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "whyChoose",
};
</script>

<style scoped>
.choose_parent {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.choose_body_parent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.choose_body {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.choose_col {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 50px;
  border-radius: 20px;
}
.choose_col_left {
  background: var(--orange50);
}
.choose_col_right {
  background: var(--blue_light);
}
.choose_body img {
  max-width: 475px;
}

.choose_title {
  font-family: Raleway_Medium, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0;
  text-align: left;
}

.choose_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

@media (min-width: 0px) and (max-width: 567px) {
  .choose_parent {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .choose_body_parent {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .default_title {
    font-family: Raleway_Bold, sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0;
    text-align: left;
  }
  .choose_col {
    padding: 50px 24px;
  }

  .choose_title {
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
  }
}
@media (min-width: 568px) and (max-width: 1279px) {
  /*  ******************************choose ///////////////*/
  .choose_parent {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .choose_body_parent {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .default_title {
    font-family: Raleway_Bold, sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0;
    text-align: left;
  }
  .choose_col {
    padding: 50px 24px;
  }

  .choose_title {
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
  }
}
</style>
