<template>
  <div class="home">
    <Header />
    <HeroComponent />
    <GallerySection />
    <WhyChoose />
    <HowItIsWork />
    <TestiMonials />
    <ProfileExample />
    <ChooseTeacher />
    <TeachersInPlatform />
    <KnowLevel />
    <EffectComponent />
    <GuaranteeComponent />
    <ConvenientPlatform />
    <ViewPossibilities />
    <PlansComponent />
    <CreatorComponent />
    <FormComponent />
    <FooterComponent />
    <div
      class="to_up"
      id="scroll-top"
      ref="scrollTop"
      aria-label="top"
      title="Back to Top"
      @click.prevent="scrollTop"
    >
      <img
        src="@/assets/images/down.svg"
        alt=""
        style="transform: rotate(180deg)"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/components/heading/Header.vue";
import HeroComponent from "@/components/heading/HeroComponent.vue";
import GallerySection from "@/components/gallery/GallerySection.vue";
import WhyChoose from "@/components/WhyChoose.vue";
import HowItIsWork from "@/components/work/HowItIsWork.vue";
import TestiMonials from "@/components/testimonials/TestiMonials.vue";
import ProfileExample from "@/components/profile/ProfileExample.vue";
import ChooseTeacher from "@/components/teacher/ChooseTeacher.vue";
import TeachersInPlatform from "@/components/platform/teachersInPlatform.vue";
import KnowLevel from "@/components/know/KnowLevel.vue";
import EffectComponent from "@/components/EffectComponent.vue";
import GuaranteeComponent from "@/components/guarantee/GuaranteeComponent.vue";
import ConvenientPlatform from "@/components/convenient-platform/ConvenientPlatform.vue";
import ViewPossibilities from "@/components/possibilities/ViewPossibilities.vue";
import PlansComponent from "@/components/plans/PlansComponent.vue";
import CreatorComponent from "@/components/creater/CreatorComponent.vue";
import FormComponent from "@/components/form/FormComponent.vue";
import FooterComponent from "@/components/footer/FooterComponent.vue";
export default {
  name: "HomeView",

  components: {
    FooterComponent,
    FormComponent,
    CreatorComponent,
    PlansComponent,
    ViewPossibilities,
    ConvenientPlatform,
    GuaranteeComponent,
    EffectComponent,
    KnowLevel,
    TeachersInPlatform,
    ChooseTeacher,
    ProfileExample,
    TestiMonials,
    HowItIsWork,
    WhyChoose,
    GallerySection,
    HeroComponent,
    Header,
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted: function () {
    let scrollTop = this.$refs.scrollTop;
    document.addEventListener(
      "scroll",
      function () {
        if (window.pageYOffset >= 400) {
          scrollTop.classList.add("show");
        } else {
          scrollTop.classList.remove("show");
        }
      },
      false
    );
  },
};
</script>
<style>
.home {
  display: flex;
  flex-direction: column;
  gap: 175px;
  padding-bottom: 200px !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  cursor: url("@/assets/images/cursor.svg") 1 1, auto;
}
#app {
  background: var(--main_bg);
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.swiper-pagination-bullet {
  width: 50px !important;
  height: 4px;
  border-radius: 3px;
  background: var(--orange100);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--orange400) !important;
}

.to_up {
  visibility: hidden;
  position: fixed;
  bottom: 40px;
  right: 40px;
  border-radius: 50%;
  background: var(--orange600);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;
  transition: all 0.2s linear;
  transform: scale(0);
}
.show {
  display: flex;
  visibility: visible;
  transform: scale(1);
}
.close_profile {
  width: 21px;
  height: 21px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
.default_title {
  font-family: Raleway_SemiBold, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 62px;
  letter-spacing: 0;
  text-align: left;
  color: var(--gray);
}
.default_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.player_icon {
  position: absolute;
  cursor: pointer;
}
</style>
