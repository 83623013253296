export default {
  // Header
  greeting: "Hello",
  howItWorks: "How it works?",
  findTeacher: "Find a teacher",
  advantages: "Advantages",
  prices: "Prices",
  // Hero
  socialPlatform: "Social platform for teaching and studying languages",
  hereSuccess:
    "Here, successful students recommend their professional teachers. ",
  register: "Register / enter",
  findATeacher: "Find a teacher",
  magicStarts:
    "“The true magic starts when we admit those who stand behind our success”. ",
  heroAddress: "Akilay Ms. Kyrgyzstan 2023 ",
  // GALLERY
  dontRisk: "Don’t risk, don’t trust commercial. Trust people. Ask people.",
  weOffer:
    "We offer simple easy-to-use tools to assess teachers’ professional qualities. ",
  supportedByLocal: "Supported by local influencers",
  criteria: "6 criteria ",
  assessTeachers: "To assess teachers’ professional qualities",
  freeForStudents: "The platform is free for students",
  realTestimonials: "Real testimonials and recommendations from real people",
  //   whyChoose
  weHelp: "We help",
  students: "Students",
  toFindIdealTeacher:
    "To find the ideal teacher based on our community’s independent assessment and testimonials. ",
  teachers: "Teachers",
  toDevelop:
    "To develop a personal brand of a professional teacher supported by our community’s independent assessment and testimonials.",
  //   howItIsWork
  seeHowItWork: "See how it works",
  choosingATeacher: "Choosing a teacher",
  lessonRoutine: "Assessment of the teacher's proffesional qualities.",
  independentAssessment: "Independent assessment at work",
  //   Testimonials
  communityIndependent: "Community’s independent assessment in details",
  howToChoose: "How to choose a teacher",
  findYourIdealTeacher:
    "Find your ideal teacher based on professional qualities ratings: punctuality, proven capability, skills developed etc. \n",
  findYourIdealTeacher2:
    "You can also search by specialization (Business language, IT, academic language, etc.)" +
    '    "Contact your teacher and discuss the details',
  ourQualityControl: "Our quality control",
  theLessonsAreConducted:
    "The lessons are conducted inside our platform to make sure we receive real testimonials from real students.",
  inAddition:
    "In addition, the platform is convenient. Everything a teacher or a student may need is inside the platform: materials, lesson plans, tools, interactive exercises, video-calls, online whiteboard etc. ",
  testimonialsAfterLesson: "Testimonials after a lesson",
  byTheEndOf:
    "By the end of the lesson, a pop-up message appears asking to write a testimonial. The more lessons a student had, the more his or her assessment influences the teacher’s rating. ",
  //   ProfileSection
  exampleProfile: "Example of a teacher’s profile.",
  aboutMe: "About me",
  myStudentsReach:
    "My students reach their goals. My ratings confirm I earned a reputation of a competent and a strict teacher.",
  education: "Education",
  americanUniversity: "American University in Central Asia",
  certificates: "Certificates",
  testimonials: "Testimonials",
  readMore: "Read more",
  provenCapability: "Proven capability",
  strictness: "Strictness",
  punctuality: "Punctuality",
  overalLessons: "Overall lessons",
  skillsDeveloped: "Skills developed",
  lessonPrice: "per lesson",
  yourPhone: "Your phone",
  yourContact: "Your contact information has been sent to the teacher",
  yourContact2: "Don't want to wait? Call yourself.",
  bySending:
    "By sending your personal data, you agree to the terms and conditions. ",
  exchangeContacts: "Exchange the contacts.",
  call: "Call",
  completedLesson: "10 lessons completed",
  studentRating: `Aigul was my tutor for a year and a half. We had
           both face-to-face and online classes, and both options completely
           satisfied. The atmosphere during busy times was friendly,
           I felt an individual approach.`,
  //   chooseTeacher
  howToFind: "How to find your ideal teacher",
  clearAssessment: "Clear assessment criteria",
  whatIsPunctuality: "How to find the most punctual teachers?",
  howToFindACompetentTeacher: "How to find a competent teacher",
  howToFindAResultOrientedTeacher: "How to find a teacher who gives results",
  howToFindThePerfectTeacher: "How to find the ideal teacher",
  demanding: "Demanding high standards",
  howLesson: "How is the lesson going?",
  aboutUs: "About Us",
  //     teachers In Platform
  ourTeachers: "Our teachers",
  aigul: "Aigul",
  experience: "Certified teacher with 5 year experience",
  readMoreBtn: "Read more",
  chooseATeacher: "Choose a teacher",
  becomeATeacher: "Become a teacher",
  //     level
  whatIsYourLevel: "What is your language proficiency level?",
  doTheTest:
    "Do the test and we send you video with error analysis, individual study plan, and choose the ideal teacher for you. ",
  tryItNow: "Try it now",
  //   help learners
  weHelpLearners: `
We help learners and educators to find each other and develop a meaningful relationship based on effective learning `,
  //   Guaranteed
  guaranteedBenefits: "Guaranteed benefits for students and teachers",
  toTheStudents: "To the students",
  realRating: `Real testimonials from real people instead of another beautiful commercial with empty promises`,
  riskFree:
    "Risk-free strategy to find your ideal teacher based on experience of previous users",
  studyMotivated: "Study with most motivated teachers",
  workWithout:
    "Work without intermediaries, pay only for your teacher’s professionalism, avoid unnecessary expenses including third party salary",
  noWaitList:
    "No waiting lists. Directly contact your teacher to arrange details quickly and effectively. ",
  easyLearn:
    "Easily learn foreign language with the help of professional teachers. ",
  //   teacher
  toTheTeacher: "To the teachers",
  anOpportunity:
    "An opportunity to develop your professional brand supported by our community’s independent assessment and testimonials.",
  avoidEmotional:
    "Avoid emotional burnout. You will receive regular feedback from your students, including words of gratitude and suggestions for further development of your professional qualities.",
  theOpportunityToTeach:
    "The opportunity to teach, independently determining the price, taking the entire receipt for yourself",
  increaseCost: `Increase the cost of an hour of classes by actually proving your
                 competencies`,
  increaseYourIncome:
    "Increase your income by switching from individual to group lessons",
  workWithoutInter:
    "Work without intermediaries, organize work in a way that is convenient for the student and you",
  // convenient-platform
  convenientTitle: "One convenient platform instead of ten over-head apps. ",
  allYouNeed: "All you need in one place",
  doUVideoCall:
    "Do you video call on zoom? Do you read books in another window? Do you use interactive whiteboard? On our platform you will find it all in a single window",
  trackProgress: "Track progress ",
  inStudents: "In students’ profile, you can see homework performance",
  stableVideoCall: "Stable video calls",
  hdQuality:
    "HD quality video calls with minimum time latency even if internet traffic is low.",
  affordablePrice: "Affordable prices",
  weTake:
    "We take a symbolic monthly payment from the teachers, which doesn't influence on the lesson fees.",
  // view possibilities
  seeWhatInside: "See what inside the platfrom",
  tryItYourself: "Try it yourself",
  audio: " Audio",
  test: "Tests",
  dictionary: "Dictionary",
  board: " Board for work",
  //   Plans
  planPrices: "Prices",
  forThePlatform: "$ for the platform itself",
  useItForFree: "Use it for free",
  tryIt: "Try it",
  currency: "$10",
  monthly: "USD monthly",
  firstMonth: "First month for free",
  //    creator
  aboutFounder: "About our founder",
  ceoName: "Ruslan Leontev",
  ceoExperience:
    "An academic English tutor with 11-year experience in teaching. ",
  ceoUniversity: "A graduate of American University in Central Asia",
  ceo1Text: `
   I speak English because I come from a small town. 

It is in the big cities that the neon lights from billboards captivate one’s imagination. Advertisement and commercial rule the world. They make us hallucinate about superpower of education and self-development. Of course, they sell courses. 

  `,
  ceo2Text: `
 None of it is like that in my town. We don’t even have any English courses, and the Internet is too slow. Therefore, we have not heard of any advertising of educational services. “If you want to study English, go to Galina Dzhoraevna”, that’s what people say in my hometown referring to the locally-known private teacher. Here people live in a close community, exchange news, recommend specialists to each other, and one’s good reputation is the only marketing strategy. 
In 2007, after returning from the army, I decided I want to get education. Of course, I turned to Galina for help. Taking lessons at Galina’s place, I discovered a whole new world in which young people, the Galina’s students, fluently spoke English. However, they didn’t stop on that ...

  `,
  ceo3Text: `
Galina’s students strived for excellence, a super result: the highest score on international exams (TOЕFL, IELTS, SAT). They dreamt big: entering a prestigious university abroad, studying under full-ride scholarship. Alisher got into the University of Washington. Spartak went to American University in Bulgaria. 
In four years, at the commencement ceremony in American University of Central Asia, among graduates, there were six students of Galina including myself. 
Back in 2007, when I knocked the door of my English teacher’s house for the first time, education abroad wasn’t a popular advertisement slogan, and many of my progressive friends from the capital city wouldn’t believe it was possible. Yet, we, the students of Galina, achieved high results in learning foreign languages. 

  `,
  ceo4Text: `
  People live differently in big cities. Everybody is in a hurry. They rarely talk to each other, rarely have a chance to recommend each other truly professional teachers. If they happen to share their success stories, they do it in a wrong way. Driven by a myth of self-made man, they fail to reveal the names of those who guided them to success. Thus, the names of professional teachers remains unknown.  The advertisement fills in the gap. Thus, the advertisment becomes more important than teachers’ reputation. 
  `,
  ceo5Text: `
I offer a simple strategy in the choice of education provider. Don't believe the advertising. Trust only independent assesment and testimonials. Lingua.community offers independent assessment of English teachers based on their ratings and honest reviews.  `,
  ceo6Text: `
The project is devoted to the memory of Mrs Akhmedova Galina Dzhoraevna, a truly dedicated teacher.
  `,
  hidden: "Hidden",
  seeMore: "Read more",
  //    form component
  startTeaching: "Start teaching or learning languages today.",
  byRegister: `
By registering on the Lingua.community website, you become part of a new culture. We are a community of successful and self-sufficient students who say thank you to truly professional teachers and recommend them to other people.
If you need help or advice, we will be happy to be with you


  `,
  connectAndJoin: "Connect and join",
  talkToUs: "Talk to us in telegram",
  // footer
  companyName: "Leontev and Partners Ltd.",
  terms: "Terms and conditions",
  personalInfo: "Personal information policy ",
  europe:
    "Funded by Accelerate Prosperity (European Development Fund + Aga Khan Foundation)",
  socialNetworks: "We are in social networks",
  techSupport: "Technical support in live chat",
  //   rating
  firstRatingName: "Akylai Kalberdieva",
  firstRatingDescription:
    "I have been studying English with you for three months, and I have increased language proficiency from the pre-intermediate to advanced level.",
  firstRatingLessons: "42 lessons passed.",
  secondRatingName: "Jamilya Dunganova",
  secondRatingDescription: `
I have been studying English with you for three months, and I have increased language proficiency from the pre-intermediate to advanced level.
  `,
  secondRatingLessons: "158 lessons passed. ",
  thirdRatingName: "Salizhan Zhumaliev",
  thirdRatingDescription: `I am 17 years old. I recently passed IELTS with the overall band of 7.5 points. I am thankful to my English teacher, Leontev Ruslan. I recommend him to everyone. `,
  thirdRatingLessons: "216 lessons passed",
  fourRatingName: "Adel Asanalieva",
  fourRatingDescription: `I entered Anglia Ruskin University in Cambridge. I majored in biomedicine. I wanted to express my gratitude to my academic English tutor, Ruslan Leontiev, who helped me prepare for international exams and get high scores, enter a prestigious university, last but not least, I am thankful to Rulsan for my personal growth and development of academic skills `,
  fourRatingLessons: "314 lessons passed. ",
};
