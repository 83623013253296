import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./assets/styles/global.css";
Vue.config.productionTip = false;
import { i18n } from "@/locales/i18n";
import AOS from "aos";
import "aos/dist/aos.css";
// import VuePlyr from "vue-plyr";
// import "vue-plyr/dist/vue-plyr.css";
new Vue({
  created() {
    AOS.init();
  },
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
