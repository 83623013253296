<template>
  <div class="container">
    <footer>
      <div class="footer_row">
        <div class="footer_col">
          <logo-component />
          <div class="footer_col_texts">
            <div class="footer_col_text">{{ $t("companyName") }}</div>
            <div class="footer_col_text">
              {{ $t("terms") }}
            </div>
            <div class="footer_col_text">{{ $t("personalInfo") }}</div>
          </div>
        </div>
        <div class="footer_col custom-pt-8 mobile_hidden">
          <div class="footer_col_texts">
            <div class="footer_col_text">
              {{ $t("europe") }}
            </div>
            <div class="footer_logo">
              <img
                src="@/assets/images/footer_logo.png"
                alt=""
                draggable="false"
              />
            </div>
          </div>
        </div>
        <div class="footer_col custom-pt-4">
          <div
            class="footer_col_texts footer_end d-flex flex-column justify-end text-end"
          >
            <div class="footer_col_heading">
              <div class="footer_col_text text-end">
                {{ $t("socialNetworks") }}
              </div>
              <div class="footer_icons">
                <a href="https://t.me/leontevrus">
                  <img
                    src="@/assets/images/orange_tg.svg"
                    alt=""
                    draggable="false"
                  />
                </a>
                <a
                  href="https://Instagram.com/lingua.community/"
                  target="_blank"
                >
                  <img
                    src="@/assets/images/orange_insta.svg"
                    alt=""
                    draggable="false"
                  />
                </a>
              </div>
            </div>
            <div class="footer_bottom">
              <a href="https://t.me/linguacommunity" class="outline_orange_btn">
                <img src="@/assets/images/bg_tg.svg" alt="" draggable="false" />
                <div>{{ $t("techSupport") }}</div>
              </a>
            </div>
          </div>
        </div>
        <div class="footer_col custom-pt-8 desktop_hidden">
          <div class="footer_col_texts">
            <div class="footer_col_text">
              {{ $t("europe") }}
            </div>
            <div class="footer_logo">
              <img
                src="@/assets/images/footer_logo.png"
                alt=""
                draggable="false"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import LogoComponent from "@/components/heading/LogoComponent.vue";

export default {
  name: "footerComponent",
  components: { LogoComponent },
};
</script>

<style scoped>
.footer_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /*display: grid;*/
  /*grid-template-columns: 1fr 1fr 1fr;*/
}

.footer_col {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.custom-pt-8 {
  padding-top: 8px;
}
.custom-pt-4 {
  padding-top: 4px;
}

.footer_col_text {
  font-family: Manrope_Regular, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 370px;
}

.footer_logo img {
  width: 189px;
  margin-top: 8px;
}

.footer_end {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.footer_icons {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}
.footer_bottom .outline_orange_btn {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 12px;
  text-decoration: none;
  color: var(--gray);
}
.desktop_hidden {
  display: none;
}

@media screen and (max-width: 567px) {
  .mobile_hidden {
    display: none;
  }
  .desktop_hidden {
    display: block;
  }
  .footer_row {
    display: flex;
    gap: 36px;
  }
  .custom-pt-4 .footer_col_text {
    display: flex;
    text-align: start !important;
  }
  .footer_icons {
    justify-content: flex-start;
  }
  .footer_col_heading {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .footer_bottom {
    width: 100%;
  }
}

@media (min-width: 568px) and (max-width: 1279px) {
  .desktop_hidden {
    display: none;
  }
  .footer_row {
    display: flex;
    gap: 36px;
  }
  .custom-pt-4 {
    width: 100%;
  }
  .custom-pt-4 .footer_col_texts {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: unset !important;
    width: 100%;
  }
  .custom-pt-4 .footer_col_text {
    display: flex;
    text-align: start !important;
  }
  .footer_icons {
    justify-content: flex-start;
  }
  .footer_col_heading {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
