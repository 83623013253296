<template>
  <div class="header">
    <div class="container">
      <div class="header_inside">
        <router-link to="/" class="logo">
          <logo-component />
        </router-link>
        <ul class="lists" :class="isOpen ? 'opened_lists' : 'closed_lists'">
          <li class="list">
            <a href="#how_it_work">{{ $t("howItWorks") }}</a>
          </li>
          <li class="list">
            <a href="#guarantee">{{ $t("findTeacher") }}</a>
          </li>
          <li class="list">
            <a href="https://lingua.community/skills/english">{{
              $t("advantages")
            }}</a>
          </li>
          <li class="list">
            <a href="#plans">{{ $t("prices") }}</a>
          </li>
          <img
            @click="isOpen = false"
            class="close_img pointer"
            src="@/assets/images/header/close.svg"
            alt="close"
            title="close"
            aria-label="close"
          />
        </ul>
        <div class="mobile_lang_and_burger">
          <LangComponent />
          <img
            class="burger_img pointer"
            src="@/assets/images/header/burger.svg"
            alt="burger"
            title="burger"
            aria-label="burger"
            @click="isOpen = true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LangComponent from "@/components/heading/LangComponent.vue";
import LogoComponent from "@/components/heading/LogoComponent.vue";

export default {
  name: "NavBar",
  data() {
    return {
      isOpen: false,
    };
  },
  updated() {
    if (this.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  },
  components: { LogoComponent, LangComponent },
};
</script>

<style scoped>
.header {
  display: flex;
  padding-top: 73px;
  padding-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 9;
  background: var(--main_bg);
}
.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.header_inside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1069px;
  width: 100%;
}
.lists {
  display: flex;
  gap: 28px;
}
.lists .list {
  list-style: none;
}
.lists .list a {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
  text-decoration: none;
}

@media (min-width: 0px) and (max-width: 567px) {
  .swiper-pagination {
    transform: translateY(30px);
  }
  .test_for_mobile {
    display: flex;
    margin-top: 50px;
  }
  .test_for_pc {
    display: none;
  }
  .test_right {
    transform: translate(0) !important;
  }
  .test_right img {
    max-width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    transform: translate(0) !important;
  }
  .home {
    padding: 20px;
    gap: 100px;
  }
  .burger_img {
    display: flex;
  }
  .header {
    padding-top: 10px;
  }
  .lists {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 20px 60px 0 #bdbdbd33;
    background: var(--white);
    width: 100%;
    padding: 20px 20px 100px 20px;
    border-radius: 20px;
  }
  .close_img {
    display: flex;
    position: absolute;
    right: 20px;
  }
  .closed_lists {
    transition: all 0.3s linear;
    transform: translateY(-100%);
  }
  .opened_lists {
    transition: all 0.3s linear;

    transform: translateY(0);
  }
  .header {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
  }
  .header_inside {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 32px;
  }
  .mobile_lang_and_burger {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
@media (min-width: 568px) and (max-width: 1279px) {
  .home {
    padding: 13px 50px;
  }
  .burger_img {
    display: flex !important;
  }
  .header {
    padding-top: 20px;
  }
  .lists {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 50px;
    box-shadow: 0 20px 60px 0 #bdbdbd33;
    background: var(--white);
    width: 100%;
    max-width: 493px;
    padding: 38px 20px 100px 20px;
    border-radius: 20px;
  }
  .close_img {
    display: flex !important;
    position: absolute;
    right: 20px;
  }
  .closed_lists {
    transition: all 0.3s linear;
    transform: translateY(-100%);
  }
  .opened_lists {
    transition: all 0.3s linear;

    transform: translateY(0);
  }
  .header {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
  }
  .header_inside {
    display: flex;
    flex-direction: unset;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    gap: 32px;
  }
  .mobile_lang_and_burger {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 36px;
  }
}
</style>
