<template>
  <div class="container">
    <div class="test_parent">
      <div class="default_title">{{ $t("communityIndependent") }}</div>
      <div class="test_body test_for_pc">
        <div class="test_body_row">
          <div class="test_body_col">
            <div
              data-aos="fade-right"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              class="test_left"
            >
              <div class="test_left_item">
                <div class="left_number">1</div>
                <div class="left_border"></div>
              </div>
              <div class="test_right_item">
                <div class="test_title">{{ $t("howToChoose") }}</div>
                <div class="test_descriptions">
                  <div class="test_description">
                    {{ $t("findYourIdealTeacher") }}
                  </div>
                  <div class="test_description">
                    {{ $t("findYourIdealTeacher2") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="test_right" data-aos="fade-up">
              <img
                src="@/assets/images/rate/rate1.png"
                alt=""
                draggable="false"
              />
            </div>
          </div>
          <div class="test_body_col">
            <div
              class="test_left"
              data-aos="fade-right"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              data-aos-delay="100"
            >
              <div class="test_left_item">
                <div class="left_number">2</div>
                <div class="left_border"></div>
              </div>
              <div class="test_right_item">
                <div class="test_title">
                  {{ $t("ourQualityControl") }}
                </div>
                <div class="test_descriptions">
                  <div class="test_description">
                    {{ $t("theLessonsAreConducted") }}
                  </div>
                  <div class="test_description">
                    {{ $t("inAddition") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="test_right" data-aos="fade-up" data-aos-delay="100">
              <img
                src="@/assets/images/rate/rate2.png"
                alt=""
                draggable="false"
              />
            </div>
          </div>
          <div class="test_body_col">
            <div
              class="test_left"
              data-aos="fade-right"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
            >
              <div class="test_left_item">
                <div class="left_number">3</div>
              </div>
              <div class="test_right_item">
                <div class="test_title">
                  {{ $t("testimonialsAfterLesson") }}
                </div>
                <div class="test_descriptions">
                  <div class="test_description">
                    {{ $t("byTheEndOf") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="test_right" data-aos="fade-up" data-aos-delay="200">
              <img
                src="@/assets/images/rate/rate1.png"
                alt=""
                draggable="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <TestSwiper class="test_for_mobile" />
  </div>
</template>

<script>
import TestSwiper from "@/components/testimonials/TestSwiper.vue";

export default {
  name: "TestiMonials",
  components: { TestSwiper },
};
</script>

<style scoped>
.test_parent {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.test_body_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.test_right img {
  max-width: 568px;
  transform: translateY(-10px);
}
.test_left {
  display: flex;
  gap: 50px;
}
.test_right_item {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 150px;
}
.test_descriptions {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.test_title {
  font-family: Raleway_Medium, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.test_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.left_number {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background: var(--blue_100);
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.left_border {
  height: 100%;
  border-right: 3px dashed var(--blue_100);
  width: 52%;
}

.test_swiper {
  gap: 24px !important;
}
</style>
