<template>
  <div class="container">
    <div class="creator_parent">
      <div class="creator_card_left">
        <div class="default_title mobile_hidden tab_hidden">
          {{ $t("aboutFounder") }}
        </div>
        <div class="creator_card_left_texts">
          <div class="creator_card_left_text">
            {{ $t("ceo1Text") }}
          </div>
          <div class="creator_card_left_text">
            {{ $t("ceo2Text") }}
          </div>
          <transition name="slide-fade">
            <div v-if="isOpen" class="creator_card_left_texts">
              <div class="creator_card_left_text">
                {{ $t("ceo3Text") }}
              </div>
              <div class="creator_card_left_text">
                {{ $t("ceo4Text") }}
              </div>
              <div class="creator_card_left_text">
                {{ $t("ceo5Text") }}
              </div>
              <div class="creator_card_left_text">
                {{ $t("ceo6Text") }}
              </div>
            </div>
          </transition>
          <div class="show_more" @click="toggleOpen">
            {{ isOpen ? $t("hidden") : $t("seeMore") }}
          </div>
        </div>
      </div>

      <div class="creator_card_right">
        <div class="creator_card">
          <img
            class="creator_image"
            src="@/assets/images/creator/creator.png"
            alt=""
          />
          <div class="creator_texts">
            <div class="creator_name">{{ $t("ceoName") }}</div>
            <div class="creator_text">
              {{ $t("ceoExperience") }}
            </div>
            <div class="creator_text">
              {{ $t("ceoUniversity") }}
            </div>
            <div class="creator_text">
              {{ $t("certificates") }} TOЕFL, IELTS, SAT
            </div>
            <div class="creator_icons">
              <a href="https://t.me/leontevruslan" target="_blank">
                <img src="@/assets/images/creator/telegram.svg" alt="" />
              </a>
              <a href="https://instagram.com/leontyevs_school.kg/">
                <img src="@/assets/images/creator/instagram.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="default_title desktop_hidden">{{ $t("aboutFounder") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "creatorComponent",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.creator_parent {
  display: flex;
  gap: 100px;
}
.creator_card_left {
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 738px;
}

.creator_card_left_texts {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.creator_card_left_text {
  font-family: Manrope_Regular, sans-serif !important;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.show_more {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

.creator_card {
  display: flex;
  flex-direction: column;
  background: var(--blue_light);
  padding: 40px;
  gap: 30px;
  border-radius: 24px;
}
.creator_card .creator_image {
  width: 120px;
  height: 100%;
}

.creator_texts {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.creator_name {
  font-family: Raleway_Medium, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
}
.creator_text {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.creator_icons {
  display: flex;
  gap: 24px;
  cursor: pointer;
}

.desktop_hidden {
  display: none;
}
@media screen and (max-width: 567px) {
  .desktop_hidden {
    display: block;
  }
  .creator_parent {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
  }
  .creator_card_left {
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 738px;
  }
  .creator_card {
    padding: 40px 24px;
    gap: 30px;
  }
  .creator_name {
    font-family: Raleway_Medium, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
  }
  .creator_text {
    font-family: Manrope_Regular, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@media (min-width: 568px) and (max-width: 1279px) {
  .desktop_hidden {
    display: block;
  }
  .tab_hidden {
    display: none;
  }
  .creator_parent {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
  }
  .creator_card_left {
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: max-content;
  }
  .creator_card {
    display: flex;
    flex-direction: unset;
    padding: 40px 24px;
    gap: 30px;
  }
  .creator_name {
    font-family: Raleway_Medium, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
  }
  .creator_text {
    font-family: Manrope_Regular, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
}
</style>
