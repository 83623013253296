<template>
  <div class="container">
    <div class="effect_parent">
      <div class="effect_card">
        <img src="@/assets/images/effect.png" alt="" />
        <div class="effect_text">{{ $t("weHelpLearners") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "effectComponent",
};
</script>

<style scoped>
.effect_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 44px;
  padding: 60px 0;
  border-radius: 24px;
  background: var(--white);
}

.effect_text {
  font-family: Raleway_Medium, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 567px) {
  .effect_card img {
    width: 100%;
  }
  .effect_text {
    font-family: Manrope_Regular, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }
}

@media (min-width: 568px) and (max-width: 1279px) {
  .effect_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 32px 0;
    border-radius: 13px;
    background: var(--white);
  }

  .effect_card img {
    max-width: 363px;
    width: 100%;
  }
  .effect_text {
    font-family: Manrope_Regular, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 586px;
  }
}
</style>
