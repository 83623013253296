<template>
  <div class="container">
    <div class="know_level_card" data-aos="fade-up" data-aos-duration="500">
      <div class="level_card_left">
        <div class="card_left_texts">
          <div class="card_left_title">{{ $t("whatIsYourLevel") }}</div>
          <div class="card_left_description">
            {{ $t("doTheTest") }}
          </div>
        </div>
        <div class="d-block left_card_btn">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdsnb-ovhQSVV7tMRRLEBS2uFikyorKvoN8Htbib34tM5o0Xw/viewform"
            target="_blank"
            class="orange_btn"
          >
            {{ $t("tryItNow") }}
          </a>
        </div>
      </div>
      <div class="level_card_right">
        <img
          src="@/assets/images/know/know_level.svg"
          draggable="false"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "knowLevel",
  components: {},
};
</script>

<style scoped>
.know_level_card {
  background: var(--orange50);
  /*display: grid;*/
  /*grid-template-columns: 1fr 1fr;*/
  display: flex;
  justify-content: space-between;
  gap: 51px;
  padding: 50px;
  border-radius: 24px;
}

.level_card_left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  max-width: 480px;
}
.card_left_texts {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card_left_title {
  font-family: Raleway_SemiBold, sans-serif;
  font-size: 42px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.card_left_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.level_card_right {
  display: flex;
  justify-content: flex-end;
}

.level_card_right img {
  max-width: 296px;
  width: 100%;
}
</style>
