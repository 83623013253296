<template>
  <div class="profile_card" data-aos="fade-up" data-aos-duration="500">
    <div class="profile_card_left">
      <img src="@/assets/images/profile/profile.png" alt="" />
    </div>
    <div class="profile_card_center">
      <div class="profile_name">{{ $t("ceoName") }}</div>
      <div class="profile_descriptions">
        <div class="profile_info">{{ $t("aboutMe") }}</div>
        <div class="profile_description">
          {{ $t("myStudentsReach") }}
        </div>
      </div>
      <div class="profile_descriptions">
        <div class="profile_info">{{ $t("education") }}</div>
        <div class="profile_description">
          American University in Central Asia
        </div>
      </div>
      <div class="profile_descriptions">
        <div class="profile_info">{{ $t("certificates") }}</div>
        <div class="profile_description">TOЕFL, IELTS, SAT</div>
      </div>
      <div class="profile_rating">
        <div class="profile_rating_heading">
          <div class="rating_name">{{ $t("testimonials") }}</div>
          <div class="rating_btn" @click="opened">
            {{ $t("readMore") }}
            <img src="@/assets/images/profile/down.svg" alt="" />
          </div>
        </div>
        <div class="profile_rating_body">
          <!--          :class="isOpen ? 'hull_height' : 'no_height'"-->
          <transition name="slide-fade">
            <opened-rating v-if="isOpen" key="opened-rating" />
          </transition>
        </div>
      </div>
      <div class="desktop_profile">
        <div class="profile_bottom">
          <div class="bottom_input">
            <input type="number" class="input" :placeholder="$t('yourPhone')" />
          </div>
          <div class="orange_btn" @click="toggleProfile">
            {{ $t("exchangeContacts") }}
          </div>
        </div>
      </div>
      <div class="desktop_profile">
        <div class="profile_bottom_description">
          {{ $t("bySending") }}
        </div>
      </div>
    </div>
    <div class="profile_stats">
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon red"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">4.8</div>
          <div class="profile_stats_item_right_description">
            {{ $t("provenCapability") }}
          </div>
        </div>
      </div>
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon yellow"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">5.0</div>
          <div class="profile_stats_item_right_description">
            {{ $t("strictness") }}
          </div>
        </div>
      </div>
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon green"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">4.6</div>
          <div class="profile_stats_item_right_description">
            {{ $t("punctuality") }}
          </div>
        </div>
      </div>
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon blue"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">320</div>
          <div class="profile_stats_item_right_description">
            {{ $t("overalLessons") }}
          </div>
        </div>
      </div>
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon orange"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">12$</div>
          <div class="profile_stats_item_right_description">
            {{ $t("lessonPrice") }}
          </div>
        </div>
      </div>
    </div>
    <div class="mobile_profile">
      <div class="profile_bottom">
        <div class="bottom_input">
          <input type="number" class="input" placeholder="Ваш телефон" />
        </div>
        <div class="profile_bottom_description">
          {{ $t("bySending") }}
        </div>
        <div class="orange_btn" @click="toggleProfile">
          {{ $t("exchangeContacts") }}
        </div>
      </div>
    </div>
    <v-dialog v-model="isOpen1" width="auto" overlay-color="rgba(0, 0, 0, 1)">
      <div class="video_card profile_pop_up">
        <div class="video_card_heading">
          <div class="video_title"></div>
          <img
            @click="isOpen1 = false"
            class="pointer close_profile"
            src="@/assets/images/header/close.svg"
            alt="close"
            title="close"
            aria-label="close"
          />
        </div>
        <div
          class="video_card_body d-flex flex-column justify-center align-center dialog_parent"
        >
          <img
            class="pointer"
            src="@/assets/images/pop_up.svg"
            alt="close"
            title="close"
            aria-label="logo"
            style="width: 100px"
          />
          <div class="dialog_title text-center">
            {{ $t("yourContact") }} <br />
            {{ $t("yourContact2") }} <br />
          </div>
          <a href="tel:+996-554-877-853" class="dialog_number"
            >+996-554-877-853</a
          >
          <a href="tel:+996-554-877-853" class="orange_btn">{{ $t("call") }}</a>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import OpenedRating from "@/components/profile/OpenedRating.vue";

export default {
  name: "ProfileCard",
  components: { OpenedRating },
  data() {
    return {
      isOpen: false,
      isOpen1: false,
    };
  },
  methods: {
    opened() {
      this.isOpen = !this.isOpen;
    },
    toggleProfile() {
      console.log("hello");
      this.isOpen1 = !this.isOpen1;
    },
  },
};
</script>

<style>
.hull_height {
  height: auto;
  transition: height 0.3s linear;
}
.no_height {
  overflow: hidden;
  height: 0;
  transition: height 0.3s linear;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.profile_parent {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.profile_card {
  background: var(--white);
  padding: 50px;
  gap: 40px;
  display: flex;
  box-shadow: 0px 0px 18.899999618530273px 0px #0000000a;
  border-radius: 24px;
}

.profile_card_left img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
}

.profile_name {
  font-family: Raleway_SemiBold, sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}
.profile_card_center {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 572px;
}

.profile_descriptions {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.profile_info {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray3);
}
.dialog_title {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}
.profile_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.profile_rating_heading {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--ui_grey_30);
  border-bottom: 1px solid var(--ui_grey_30);
  gap: 50px;
  height: 56px;
  align-items: center;
  padding: 4px 0;
}

.rating_name {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.rating_btn {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray3);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.rating_btn img {
  padding-left: 12px;
}

.profile_bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.profile_bottom_description {
  max-width: 280px;
}
.profile_bottom_description,
.profile_bottom_description a {
  font-family: Manrope_Regular, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray3) !important;
}

.opened_rating_card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 12px;
  border-bottom: 1px solid #cfd5dc;
  padding-bottom: 10px;
}
.opened_rating_card_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ava_gallery {
  width: 38px;
  height: 38px;
  object-fit: cover;
  border-radius: 50%;
}

.rating_heading_name {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
  padding-left: 12px;
}

.rating_numbers {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray3);
}

.rating_numbers img {
  padding-right: 4px;
}
.rating_get_lessons {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray3);
}

.opened_rating_card_body {
}

.opened_rating_card_body_text {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ui_grey_60);
}
.profile_stats_item_left_icon {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.profile_stats {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.profile_stats_item {
  display: flex;
  gap: 10px;
}

.profile_stats_item_right_name {
  font-family: Inter_Regular, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}
.dialog_number {
  font-family: Inter_Medium, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--gray) !important;
  text-decoration: none;
}
.profile_stats_item_right_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray3);
}

.profile_stats_item_left_icon {
  transform: translateY(10px);
}
.profile_pop_up {
  background: var(--orange50);
  border-radius: 24px;
  padding-left: 80px;
  padding-right: 80px;
  overflow: hidden;
  position: relative;
}
.dialog_parent {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
</style>
