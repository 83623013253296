<script>
import Swiper, { Navigation, Pagination } from "swiper";
import { i18n } from "@/locales/i18n.js";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "teacherCarousel",
  data() {
    return {
      slider: [],
      isOpen1: false,
      item: {},
      videoPlayer: null,
    };
  },
  methods: {
    toggleVideo(item) {
      this.isOpen1 = !this.isOpen1;
      if (this.isOpen1) {
        this.item = item;
      } else {
        this.item = {};
      }
    },
    updateSlider() {
      this.slider = [
        {
          id: 1,
          image: "/teacher/1.png",
          text: i18n.t("whatIsPunctuality"),
          video_link:
            "https://www.youtube.com/embed/HoHihTK4ZY0?si=1Gw5W326X3jogmNx",
        },
        {
          id: 2,
          image: "/teacher/2.png",
          text: i18n.t("howToFindACompetentTeacher"),
          video_link:
            "https://www.youtube.com/embed/1FcvmW06i_k?si=_5TVbqYKJ916cCg-",
        },
        {
          id: 3,
          image: "/teacher/3.png",
          text: i18n.t("howToFindAResultOrientedTeacher"),
          video_link:
            "https://www.youtube.com/embed/QF7fr_HLU9s?si=I9VM2pbabSw51WNX",
        },
        {
          id: 5,
          image: "/teacher/5.png",
          text: i18n.t("demanding"),
          video_link:
            "https://www.youtube.com/embed/FkmZ2NJi4Ss?si=JHYcTL8JP4KVE-1e",
        },
        {
          id: 6,
          image: "/teacher/6.png",
          text: i18n.t("aboutUs"),
          video_link:
            "https://www.youtube.com/embed/huVFl-7_MIw?si=1-BdF725We9XkHG3",
        },
        {
          id: 4,
          image: "/teacher/4.png",
          text: i18n.t("aboutUs"),
          video_link:
            "https://www.youtube.com/embed/huVFl-7_MIw?si=1-BdF725We9XkHG3",
        },
      ];
      this.initSwiper();
    },
    initSwiper() {
      new Swiper(this.$refs.swiper, {
        modules: [Navigation, Pagination],
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });
    },
    closeDialog(event) {
      // Check if the event is triggered by a keyboard press and if the key pressed is "k"
      if (
        event &&
        event.type === "keydown" &&
        event.key.toLowerCase() === "k"
      ) {
        this.isOpen1 = false;
      } else {
        // Close the dialog when the close button is clicked
        this.isOpen1 = false;
        this.pauseVideo();
      }
    },
    pauseVideo() {
      if (this.videoPlayer && this.videoPlayer.contentWindow) {
        // Send message to pause the video
        this.videoPlayer.contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          "*"
        );
      }
    },
  },
  async mounted() {
    await this.updateSlider();
    this.videoPlayer = this.$refs.videoPlayer;
  },
  beforeMount() {
    this.updateSlider();
  },
  watch: {
    "$i18n.locale"() {
      this.updateSlider();
    },
    isOpen1(newVal) {
      if (
        newVal === false &&
        this.videoPlayer &&
        this.videoPlayer.contentWindow
      ) {
        this.videoPlayer.contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          "*"
        );
      }
    },
  },
};
</script>

<template>
  <div class="swiper_parent pl-7 pr-7">
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in slider" :key="item.id">
          <div class="teacher_card">
            <div class="teacher_card_images">
              <img
                class="teacher_card_img"
                :src="item.image"
                :alt="item.text"
                :title="item.text"
                draggable="false"
              />
              <img
                src="@/assets/images/work/player.svg"
                alt="player"
                aria-label="toggle"
                draggable="false"
                title="player"
                class="player_icon"
                @click.prevent="toggleVideo(item)"
              />
            </div>
            <div class="teacher_description">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>

      <div class="swiper-scrollbar"></div>
    </div>
    <v-dialog v-model="isOpen1" width="auto" overlay-color="rgba(0, 0, 0, 1)">
      <div class="video_card">
        <div class="video_card_heading">
          <div class="video_title">{{ item.text }}</div>
          <img
            @click="closeDialog"
            class="pointer"
            src="@/assets/images/header/close.svg"
            alt="close"
            title="close"
            aria-label="close"
          />
        </div>
        <div class="video_card_body" v-if="item.video_link">
          <iframe
            v-if="isOpen1"
            width="1080"
            height="540"
            class="video_iframe"
            :src="item.video_link"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            :autoplay="isOpen1"
            ref="videoPlayer"
          ></iframe>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<style scoped>
.swiper-button-next,
.swiper-button-prev {
  background: transparent !important;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid transparent;
  color: var(--gray) !important;
}
.swiper_parent {
  overflow: hidden;
}
.swiper {
  overflow: visible;
  min-height: 260px;
}
.swiper-button-prev {
  position: absolute;
  left: -35px;
  top: 85px;
}
.swiper-button-next {
  position: absolute;
  right: -35px;
  top: 85px;
}

.swiper-button-next::after {
  position: absolute;
  top: 5%;
  left: 20%;
  width: 10px;
  height: 10px;
  padding: 10px;
  font-size: 20px;
}

.swiper-button-prev::after {
  position: absolute;
  top: 5%;
  left: 10%;
  color: var(--gray) !important;
  width: 10px;
  height: 10px;
  padding: 10px;
  font-size: 20px;
}
.swiper-pagination-bullet {
  width: 50px !important;
  height: 4px;
  border-radius: 3px;
  background: var(--orange100);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--orange400) !important;
}

.teacher_card_img {
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
}
.teacher_card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.teacher_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.teacher_card_images {
  display: flex;
  justify-content: center;
  align-items: center;
}
.teacher_card_images .player_icon {
  width: 41px;
  height: 41px;
}

.video_card {
  background: var(--white);
  padding: 40px;
}
.video_card_heading {
  display: flex;
  justify-content: space-between;
}
.video_title {
  font-family: Raleway_Medium, sans-serif, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: var(--gray);
  padding-bottom: 20px;
}
.video_card_body {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
}
.video_card_body video {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  max-width: 100vh;
}

@media screen and (max-width: 567px) {
  .video_card_body .video_iframe {
    height: 100% !important;
    min-height: 190px;
  }
}
</style>
