export default {
  // Header
  greeting: "Привет",
  howItWorks: "Как это работает",
  findTeacher: "Преподаватели",
  advantages: "Преимущества",
  prices: "Тарифы",

  // Hero
  socialPlatform:
    "Онлайн-платформа для изучения и преподавания английского языка",
  hereSuccess:
    "Здесь ученики говорят «Спасибо» по-настоящему профессиональным учителям и рекомендуют их другим людям.",
  register: "Регистрация и вход",
  findATeacher: "Найти преподавателя",
  magicStarts:
    "«Я уважаю таких людей с высокой внутренней культурой и доверяю их мнению. Доверяю больше, чем самой красивой рекламе».",
  heroAddress: "Акылай, мисс Кыргызстан 2023",

  // GALLERY
  dontRisk: "Не верьте рекламе. Доверяйте отзывам и «сарафанному радио».",
  weOffer:
    "Мы предлагаем простые и доступные инструменты для оценки профессионализма учителей английского языка.",
  supportedByLocal: "Нас поддерживают известные блогеры",
  criteria: "6 критериев",
  assessTeachers: "Оценки качества обучения преподавателей",
  freeForStudents: "Бесплатно для учеников",
  realTestimonials: "Реальные отзывы учеников",

  //   whyChoose
  weHelp: "Мы помогаем",
  students: "Ученикам",
  toFindIdealTeacher:
    "Найти идеального учителя на основе отзывов и независимой оценки профессиональных качеств. Получить образование, которое полностью удовлетворяет вашим целям.",
  teachers: "Преподавателям",
  toDevelop:
    "Создать бренд преподавателя-профессионала и продвигать себя на основе отзывов, независимой оценки профессиональных качеств, репутации и честной конкуренции.",
  //   howItIsWork
  seeHowItWork: "Смотрите, как это работает",
  choosingATeacher: "Как выбрать преподавателя",
  lessonRoutine: "Как проходит оценка учителей",
  independent: "Независимая оценка",

  //   Testimonials
  communityIndependent: "Подробнее о системе отзывов и оценок",
  howToChoose: "Выбор преподавателя",
  findYourIdealTeacher:
    "Ученик выбирает преподавателя на основе четких критериев оценки профессионализма (пунктуальность, знание предмета, подача материала урока, требовательность, мягкость, результативность и т.д).",
  findYourIdealTeacher2:
    "Можно также выбрать преподавателя по специальности (английский для бизнеса, IT и т.д.) или по цене. Также можно написать преподавателю напрямую.",

  ourQualityControl: "Честный подход",
  theLessonsAreConducted:
    "The lessons are conducted inside our platform to make sure we receive real testimonials from real students.",
  inAddition:
    "В дополнение, платформа очень удобна. Все необходимое для учеников и преподавателей уже есть на платформе: учебники, планы обучения, интерактивные упражнения, аудирование, онлайн-созвон, доска для совместной работы и многое другое.",
  testimonialsAfterLesson: "Отзыв после урока",
  byTheEndOf:
    "В конце занятия появляется форма для заполнения отзыва. Ученик по желанию может поставить оценку учителю и написать отзыв. Чем больше уроков было у ученика, тем, больше влияет его оценка и отзыв на рейтинг преподавателя.",

  //   ProfileSection
  exampleProfile: "Пример профиля преподавателя",
  aboutMe: "Обо мне",
  myStudentsReach:
    "Мои ученики добиваются своих целей. Я заработал репутацию хорошего предметника, результативного и требовательного репетитора и об этом говорят оценки моего профессионализма и отзывы учеников.",
  education: "Образование",
  americanUniversity: "American University in Central Asia",
  certificates: "Сертификаты",
  testimonials: "Отзывы",
  readMore: "Смотреть",
  provenCapability: "подача материала",
  strictness: "требовательность",
  punctuality: "пунктуальность",
  skillsDeveloped: "результативность",
  overalLessons: "количество уроков",
  lessonPrice: "цена за урок",
  yourPhone: "Ваш телефон",
  yourContact: "  Ваши контакты переданы учителю.  ",
  yourContact2: "Не хотите ждать? Позвоните сами.",

  bySending:
    "Отправляя ваши данные, вы соглашаетесь с положением Политики конфиденциальности",
  exchangeContacts: "Обменяться контактами",
  call: "Позвонить",
  completedLesson: "пройдено 10 уроков",
  studentRating: ` Айгуль была моим репетитором в течение полутора лет. У нас проводились
          как очные, так и онлайн занятия, и оба варианта меня полностью
          удовлетворили. Атмосфера во время занятой была дружелюбной,
          чувствовался индивидуальный подход. `,

  //   chooseTeacher

  howToFind: "Как выбрать учителя на нашем сайте?",
  clearAssessment:
    "Чёткие критерии оценки профессионализма учителей на базе реальных отзывов реальных людей.",
  whatIsPunctuality: "Как найти самых пунктуальных преподавателей?",
  howToFindACompetentTeacher: "Как найти компетентного преподавателя",
  howToFindAResultOrientedTeacher:
    "Как найти преподавателя, который дает результат",
  howToFindThePerfectTeacher: "Как найти идеального преподавателя",
  demanding: "Требовательный учитель. Преимущества? ",
  howLesson: "Как проходит урок?",
  aboutUs: "О проекте",

  //     teachers In Platform
  ourTeachers: "Преподаватели на платформе",
  aigul: "Айгуль",
  experience:
    "Дипломированный специалист по английской филологии. Опыт преподавания - 5 лет.",
  readMoreBtn: "Подробнее",
  chooseATeacher: "Выбрать преподавателя",
  becomeATeacher: "Стать преподавателем",

  //     level
  whatIsYourLevel: "Узнайте свой уровень английского",
  doTheTest:
    "Заполните тест. Мы отправим вам видеоразбор ваших ошибок, индивидуальный план обучения и подберем идеального преподавателя.",
  tryItNow: "Пройти тест",
  //   help learners
  weHelpLearners: `
  Мы помогаем преподавателям и ученикам найти друг друга и сделать обучение максимально эффективным
  `,

  //   Guaranteed
  guaranteedBenefits: "Гарантированная выгода ученикам и преподавателям",
  toTheStudents: "Ученикам",
  realRating:
    "Реальные отзывы реальных людей вместо очередной рекламы и пустых обещаний",
  riskFree:
    "Правильно выбрать учителя ориентируясь на четкие критерии оценки профессионализма",
  studyMotivated: "Заниматься с мотивированным учителем",
  workWithout:
    "Оплачивать учителю согласно компетенциям, без обычных затрат на зарплату администрации курсов",
  noWaitList:
    "Звонить учителю напрямую, быстро и эффективно решать организационные моменты без ожидания ответа администрации",
  easyLearn:
    "Выучить английский легко по методике, с помощью которой набирают максимальные баллы по международным экзаменам (TOЕFL, IELTS, SAT)",

  //   teacher
  toTheTeacher: "Преподавателям",
  anOpportunity:
    "Возможность создать бренд преподавателя-профессионала, подкрепленный независимой оценкой и отзывами учеников",
  avoidEmotional:
    "Избежать выгорания. Вы будете получать регулярные отзывы от своих учеников, включая слова благодарности и предложения по дальнейшему развитию",
  theOpportunityToTeach:
    "Возможность преподавать, самостоятельно определяя цену, забирая весь чек себе",
  increaseCost:
    "Увеличить стоимость часа занятий, на деле доказав свои компетенции",
  increaseYourIncome:
    "Увеличить доход, перейдя с индивидуальных на групповые занятия",
  workWithoutInter:
    "Работать без посредников, организовать работу так, как удобно ученику и Вам",

  // convenient-platform
  convenientTitle: "Одна удобная платформа вместо десяти непонятных приложений",
  allYouNeed: "Всё нужное в одном месте",
  doUVideoCall:
    "Общаетесь в Зуме, читаете книги в другом окне, отправляете файлы через Вотсап? В нашей платформе всё это есть в одном окне.",
  trackProgress: "Отслеживайте прогресс",
  inStudents:
    "В личном кабинете можно проверять домашние задания и проводить работу над ошибками.",
  stableVideoCall: "Стабильная связь",
  hdQuality:
    "Качественная связь с минимальной задержкой во времени даже при плохом интернете и защитой от выбивания",
  affordablePrice: "Доступные цены",
  weTake:
    "Мы не берем комиссию за каждый проведенный урок. Поэтому учителя могут назначать удобную для них цену.",

  // view possibilities
  seeWhatInside: "Посмотрите на все возможности платформы",
  tryItYourself: "Войти на платформу",
  audio: " Аудирование",
  test: "Тесты",
  dictionary: "Словарь",
  board: " Доска для работы",

  //   Plans
  planPrices: "Тарифы",
  forThePlatform: "₽ за пользование платформой",
  tryIt: "Попробовать",
  useItForFree: "Пользуйтесь бесплатно",
  monthly: "₽ в месяц",
  currency: "1000",
  firstMonth: "Первый месяц бесплатно",

  //    creator
  aboutFounder: "О создателе",
  ceoName: "Руслан Леонтьев",
  ceoExperience: "Преподаватель английского языка со стажем 7 лет.",
  ceoUniversity: "Выпускник American University in Central Asia",
  ceo1Text: `
   Привет! Меня зовут Руслан Леонтьев. Я родом из маленького городка, и
            в этом мне в чем-то повезло. В отличии от больших городов, где
            правят неоновые огни рекламных плакатов, в моём городке всего этого
            нет. У нас даже нет курсов английского, да и с интернетом не очень.
            Поэтому не о какой рекламе образовательных услуг мы не слышали.
            Хочешь заниматься английским? Иди к Галине Джораевне – так говорят у
            нас. Здесь люди живут тесной общиной, обмениваются новостями,
            рекомендуют друг другу специалистов, а сарафанное радио и
            собственная репутация – единственные маркетинговые технологии.
  `,
  ceo2Text: `
   В 2007 году, вернувшись с армии в родной городок, я решил заняться
            образованием. По рекомендации соседей я оказался у Галины Джораевны,
            где неожиданно для себя открыл целый мир, в котором молодые люди,
            занимавшиеся у моего учителя, начинали свободно говорить на
            английском. Однако на этом мы не останавливались...
  `,
  ceo3Text: `
  Все мечтали о сверх результате – набрать максимальные балы по
                международным экзаменам (TOЕFL, IELTS, SAT), чтобы поступить в
                престижный зарубежный университет на стипендию, и практически
                всем это удалось. Алишер поступил в University of Washington,
                Спартак в American University in Bulgaria. Через четыре года,
                среди выпускников American University in Central Asia, корме
                меня, было ещё пять учеников Галины Джораевны.
  `,
  ceo4Text: `
   В 2007 году, когда я первый раз постучался в дверь своего
                учителя, «образование за рубежом» ещё не было популярным
                рекламным лозунгом, и мои друзья из столицы крутили указательным
                пальцем у виска, говоря, «Да кто тебя вообще там ждёт?», мы,
                ученики Галины Джораевны получали большие стипендии и
                приглашения из разных университетов мира.
  `,
  ceo5Text: `
  Люди в больших городах живут по иным законам. Все спешат и всё
                реже находят время общаться, обменяться ценной информацией,
                порекомендовать друг другу специалистов. Поэтому сарафанное
                радио в городе не работает, а реклама заполняет пробел в
                общение. Оглядываясь назад я понимаю, что я человек, которому
                ужасно повезло родится в маленьком городке, иначе, Галина
                Джораевна и я не нашли друг друга. Ведь у моего учителя нет
                времени выкладывать рилсы в Инстаграм, а каждую свободную минуту
                она посвящает любимому делу – преподаванию. Она не любит
                заниматься саморекламой, бросать пыль в глаза. И вот такие вот
                преподаватели от Бога, (их кстати очень много) к сожалению, не
                могут ужиться в городе. Их просто не будет видно и слышно среди
                толпы кричащих о себе и своих услугах курсов английского языка.
  `,
  ceo6Text: `
    Я призываю людей к простоте выбора. Не верьте рекламе. Доверяйте
                отзывам и сарафанному радио. Community – это сообщество отзывов.
                Мы предлагаем независимую оценку Ваших учителей английского
                языка на основе их рейтингов и честных отзывов.
  `,

  hidden: "Скрыть",
  seeMore: "Показать ещё",

  //    form component
  startTeaching: "Начните изучать или преподавать английский уже сегодня",
  byRegister: `
  Регистрируясь на сайте Lingua.community, вы становитесь частью новой культуры. Мы — сообщество успешных и самодостаточных людей, которые говорят спасибо по-настоящему профессиональным учителям и рекомендуют их другим людям.
Если вам нужна помощь или консультация, мы будем рады помочь вам.

  `,
  connectAndJoin: "Подключиться",
  talkToUs: "Задать вопрос в Telegram",

  // footer
  companyName: "OcOO Леонтьев и Партнёры",
  terms: "Договор оказания информационных услуг",
  personalInfo: "Политика конфиденциальности",
  europe:
    "Финансируется Accelerate Prosperity (Европейский фонд развития + фонд Ага Хана)",
  socialNetworks: "Мы в соцсетях",
  techSupport: "Техническая поддержка",

  //   rating
  firstRatingName: "Акылай Калбердиева",
  firstRatingDescription:
    "За три месяца занятий с Вами я повысила свой уровень от pre-intermediate до advanced.",
  firstRatingLessons: "Пройдено 42 урока. ",

  secondRatingName: "Дунганова Джамиля",
  secondRatingDescription: `
  Мне очень понравилась методика обучения Руслана, она включала аудирование, свободное письмо, разбор грамматических тем, различные упражнения на закрепление материала. После 1 года занятий, я перешла на более высокий уровень владения английским языком, я стала лучше понимать английскую речь, составлять небольшие тексты, улучшила разговорный язык и перестала переживать по поводу правильности своего произношения.
  `,
  secondRatingLessons: "Пройдено 158 уроков ",

  thirdRatingName: "Салижан Жумалиев",
  thirdRatingDescription: `Мне 17 лет. Недавно я сдал IELTS на 7.5. За это я благодарен Леонтьеву Руслану. Всем рекомендую своего учителя. `,
  thirdRatingLessons: "Пройдено 216 уроков",

  fourRatingName: "Адель Асаналиева",
  fourRatingDescription: `Я поступила в университет. Англия Раскин в Кэбридже. По направлению биомедицина. Хотела выразить благодарность своему учителю Леонтьеву Руслану, который помогал подготовиться к международным экзаменам и получить высокие баллы, поступить в престижный университет, а также за помощь в академическом и личностном росте.  `,
  fourRatingLessons: "Пройдено 314 уроков",
};
