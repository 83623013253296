<template>
  <div class="container">
    <div class="plans_parent" id="plans">
      <div class="default_title">{{ $t("planPrices") }}</div>
      <div class="plan_body">
        <div
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
          class="plan_card plan_left"
        >
          <img src="@/assets/images/plans/plan1.png" alt="" />
          <div class="plan_body_text">
            <div class="plan_body_text_left">{{ $t("students") }}</div>
            <div>
              <span class="plan_price">0</span>
              <span class="plan_price_description">{{
                $t("forThePlatform")
              }}</span>
            </div>
          </div>
          <div class="plan_bottom">
            <a
              href="https://lingua.community/registration/student"
              class="outline_orange_btn"
              >{{ $t("tryIt") }}</a
            >
            <div class="plan_bottom_text">{{ $t("useItForFree") }}</div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="500"
          class="plan_card plan_right"
        >
          <img src="@/assets/images/plans/plan2.png" alt="" />
          <div class="plan_body_text">
            <div class="plan_body_text_left">{{ $t("teachers") }}</div>
            <div>
              <span class="plan_price">{{ $t("currency") }}</span>
              <span class="plan_price_description">{{ $t("monthly") }}</span>
            </div>
          </div>
          <div class="plan_bottom">
            <a
              href="https://lingua.community/registration/teacher"
              class="outline_btn"
              >{{ $t("tryIt") }}</a
            >
            <div class="plan_bottom_text">{{ $t("useItForFree") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "plansComponent",
  components: {},
};
</script>

<style scoped>
.plans_parent {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.plan_body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 52px;
}

.plan_card img {
  width: 100%;
}
.plan_card {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
}
.plan_left {
  background: var(--orange50);
}
.plan_right {
  background: var(--blue_light);
}

.plan_body_text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}
.plan_body_text_left {
  font-family: Raleway_Medium, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
}
.plan_price_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.plan_price {
  font-family: Inter_Regular, sans-serif;
  font-size: 33px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
}
.plan_bottom {
  display: flex;
  gap: 30px;
}

.plan_bottom .outline_btn {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.plan_bottom_text {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  max-width: 100px;
}

@media screen and (max-width: 567px) {
  .plans_parent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .plan_card {
    gap: 24px;
    padding: 40px;
  }
  .plan_body {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .plan_body_text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;
  }
  .plan_body_text_left {
    font-family: Raleway_Medium, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
  }
  .plan_price {
    font-family: Inter_Regular, sans-serif;
    font-size: 33px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: left;
  }
  .plan_bottom {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }
  .plan_bottom br {
    display: none;
  }
}

@media (min-width: 568px) and (max-width: 1279px) {
  .plans_parent {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }
  .plan_card {
    gap: 40px;
    padding: 40px;
  }
  .plan_body {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }
  .plan_body_text_left {
    font-family: Raleway_Medium, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
  }
  .plan_price {
    font-family: Inter_Regular, sans-serif;
    font-size: 33px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: left;
  }
}
</style>
