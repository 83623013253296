var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"swiper",staticClass:"swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"test_left d-flex flex-column test_swiper"},[_vm._m(0),_c('div',{staticClass:"test_right_item p-0 pb-0"},[_c('div',{staticClass:"test_title"},[_vm._v(_vm._s(_vm.$t("howToChoose")))]),_c('div',{staticClass:"test_descriptions"},[_c('div',{staticClass:"test_description"},[_vm._v(" "+_vm._s(_vm.$t("findYourIdealTeacher"))+" ")]),_c('div',{staticClass:"test_description"},[_vm._v(" "+_vm._s(_vm.$t("findYourIdealTeacher2"))+" ")])])])]),_vm._m(1)])]),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"test_left d-flex flex-column test_swiper"},[_vm._m(2),_c('div',{staticClass:"test_right_item p-0 pb-0"},[_c('div',{staticClass:"test_title"},[_vm._v(" "+_vm._s(_vm.$t("ourQualityControl"))+" ")]),_c('div',{staticClass:"test_descriptions"},[_c('div',{staticClass:"test_description"},[_vm._v(" "+_vm._s(_vm.$t("theLessonsAreConducted"))+" ")]),_c('div',{staticClass:"test_description"},[_vm._v(" "+_vm._s(_vm.$t("inAddition"))+" ")])])])]),_vm._m(3)])]),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"test_left d-flex flex-column test_swiper"},[_vm._m(4),_c('div',{staticClass:"test_right_item p-0 pb-0"},[_c('div',{staticClass:"test_title"},[_vm._v(" "+_vm._s(_vm.$t("testimonialsAfterLesson"))+" ")]),_c('div',{staticClass:"test_descriptions"},[_c('div',{staticClass:"test_description"},[_vm._v(" "+_vm._s(_vm.$t("byTheEndOf"))+" ")])])])]),_vm._m(5)])])]),_c('div',{staticClass:"swiper-pagination"}),_c('div',{staticClass:"swiper-button-prev"}),_c('div',{staticClass:"swiper-button-next"}),_c('div',{staticClass:"swiper-scrollbar"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test_left_item"},[_c('div',{staticClass:"left_number"},[_vm._v("1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test_right",staticStyle:{"transform":"translate(-20px, 0)"}},[_c('img',{attrs:{"src":require("@/assets/images/rate/rate1.png"),"alt":"","draggable":"false"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test_left_item"},[_c('div',{staticClass:"left_number"},[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test_right",staticStyle:{"transform":"translate(-20px, 0)"}},[_c('img',{attrs:{"src":require("@/assets/images/rate/rate1.png"),"alt":"","draggable":"false"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test_left_item"},[_c('div',{staticClass:"left_number"},[_vm._v("3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test_right",staticStyle:{"transform":"translate(-20px, 0)"}},[_c('img',{attrs:{"src":require("@/assets/images/rate/rate1.png"),"alt":"","draggable":"false"}})])
}]

export { render, staticRenderFns }