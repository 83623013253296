import { render, staticRenderFns } from "./TestiMonials.vue?vue&type=template&id=28e1f308&scoped=true"
import script from "./TestiMonials.vue?vue&type=script&lang=js"
export * from "./TestiMonials.vue?vue&type=script&lang=js"
import style0 from "./TestiMonials.vue?vue&type=style&index=0&id=28e1f308&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e1f308",
  null
  
)

export default component.exports