<template>
  <div class="container">
    <div class="choose_teacher_parent">
      <div class="default_title">{{ $t("ourTeachers") }}</div>
      <div class="choose_teacher_parent_body">
        <platform-carousel />
        <div class="platform_bottom">
          <a
            href="https://lingua.community/skills/english"
            class="orange_btn w-100"
            target="_blank"
            >{{ $t("chooseATeacher") }}</a
          >
          <a
            href="https://lingua.community/registration/teacher"
            class="outline_orange_btn w-100"
            target="_blank"
            >{{ $t("becomeATeacher") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlatformCarousel from "@/components/platform/PlatformCarousel.vue";

export default {
  name: "teachersInPlatform",
  components: { PlatformCarousel },
};
</script>

<style scoped>
.platform_card {
  box-shadow: 0px 0px 18.899999618530273px 0px #0000000a;
  background: var(--white);
  border-radius: 24px;
}
.platform_card_body {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 25px;
}

.platform_card_body_name {
  font-family: Raleway_Medium, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.platform_card_body_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.platform_stats {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.platform_stats_item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.platform_stats_item .profile_stats_item_left_icon {
  transform: translateY(5px) !important;
}

.platform_stats_item_number {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.platform_stats_item_text {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray3);
}
.platform_btn {
  z-index: 9;
  position: initial;
}
.platform_btn .outline_btn {
  text-align: center;
  width: 100%;
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--gray);
  z-index: 9;
  cursor: pointer;
}
.profile_card {
  position: relative;
}
.close_profile {
  width: 21px;
  height: 21px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.choose_teacher_parent_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.platform_bottom {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
