<template>
  <div class="container">
    <div class="convenient_parent">
      <div class="default_title">
        {{ $t("convenientTitle") }}
      </div>
      <div class="convenient_body">
        <div
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          class="convenient_body_col"
        >
          <div class="convenient_body_col_left">
            <img src="@/assets/images/convenient/1.svg" alt="" />
          </div>
          <div class="convenient_body_col_right">
            <div class="convenient_body_col_right_title">
              {{ $t("allYouNeed") }}
            </div>
            <div class="convenient_body_col_right_description">
              {{ $t("doUVideoCall") }}
            </div>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-delay="200"
          class="convenient_body_col"
        >
          <div class="convenient_body_col_left">
            <img src="@/assets/images/convenient/2.svg" alt="" />
          </div>
          <div class="convenient_body_col_right">
            <div class="convenient_body_col_right_title">
              {{ $t("trackProgress") }}
            </div>
            <div class="convenient_body_col_right_description">
              {{ $t("inStudents") }}
            </div>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-delay="300"
          class="convenient_body_col"
        >
          <div class="convenient_body_col_left">
            <img src="@/assets/images/convenient/3.svg" alt="" />
          </div>
          <div class="convenient_body_col_right">
            <div class="convenient_body_col_right_title">
              {{ $t("stableVideoCall") }}
            </div>
            <div class="convenient_body_col_right_description">
              {{ $t("hdQuality") }}
            </div>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-delay="400"
          class="convenient_body_col"
        >
          <div class="convenient_body_col_left">
            <img src="@/assets/images/convenient/4.svg" alt="" />
          </div>
          <div class="convenient_body_col_right">
            <div class="convenient_body_col_right_title">
              {{ $t("affordablePrice") }}
            </div>
            <div class="convenient_body_col_right_description">
              {{ $t("weTake") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "convenient_platform",
};
</script>

<style scoped>
.convenient_parent {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.convenient_body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 76px;
}

.convenient_body_col {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
}
.convenient_body_col_left img {
  width: 100%;
}
.convenient_body_col_right {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.convenient_body_col_right_title {
  font-family: Raleway_Medium, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.convenient_body_col_right_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}
.convenient_body_col_left img {
  width: 60px;
}

@media screen and (max-width: 567px) {
  .convenient_parent {
    gap: 32px;
  }
  .convenient_body {
    grid-template-columns: 1fr;
  }
  .convenient_body_col {
    flex-direction: column;
  }

  .convenient_body_col_right_title {
    font-family: Raleway_Medium, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@media (min-width: 568px) and (max-width: 1080px) {
  .convenient_parent {
    gap: 32px;
  }
  .convenient_body {
    grid-template-columns: 1fr;
  }
  .convenient_body_col {
    max-width: 550px;
  }

  .convenient_body_col_right_title {
    font-family: Raleway_Medium, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
  }
}
</style>
