<template>
  <div class="profile_card">
    <slot> </slot>
    <div class="profile_card_left">
      <img :src="item.image" alt="" />
    </div>
    <div class="profile_card_center">
      <div class="profile_name">{{ item.name }}</div>
      <div class="profile_descriptions">
        <div class="profile_info">{{ $t("aboutMe") }}</div>
        <div class="profile_description">
          {{ $t("experience") }}
        </div>
      </div>
      <div class="profile_descriptions">
        <div class="profile_info">{{ $t("education") }}</div>
        <div class="profile_description">
          American University in Central Asia
        </div>
      </div>
      <div class="profile_descriptions">
        <div class="profile_info">{{ $t("certificates") }}</div>
        <div class="profile_description">TOЕFL, IELTS, SAT</div>
      </div>
      <div class="profile_rating">
        <div class="profile_rating_heading">
          <div class="rating_name">{{ $t("testimonials") }}</div>
          <div class="rating_btn" @click="opened">
            {{ $t("readMore") }}
            <img src="@/assets/images/profile/down.svg" alt="" />
          </div>
        </div>
        <div class="profile_rating_body">
          <!--          :class="isOpen ? 'hull_height' : 'no_height'"-->
          <transition name="slide-fade">
            <opened-rating v-if="isOpen" key="opened-rating" />
          </transition>
        </div>
      </div>
      <div class="desktop_profile">
        <div class="profile_bottom">
          <div class="bottom_input">
            <input type="number" class="input" :placeholder="$t('yourPhone')" />
          </div>
          <div class="orange_btn">
            {{ $t("exchangeContacts") }}
          </div>
        </div>
      </div>
      <div class="desktop_profile">
        <div class="profile_bottom_description">
          {{ $t("bySending") }}
        </div>
      </div>
    </div>
    <div class="profile_stats">
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon red"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">4.5</div>
          <div class="profile_stats_item_right_description">
            {{ $t("provenCapability") }}
          </div>
        </div>
      </div>
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon yellow"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">5.0</div>
          <div class="profile_stats_item_right_description">
            {{ $t("strictness") }}
          </div>
        </div>
      </div>
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon green"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">5.0</div>
          <div class="profile_stats_item_right_description">
            {{ $t("punctuality") }}
          </div>
        </div>
      </div>
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon blue"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">118</div>
          <div class="profile_stats_item_right_description">
            {{ $t("overalLessons") }}
          </div>
        </div>
      </div>
      <div class="profile_stats_item">
        <div class="profile_stats_item_left">
          <div class="profile_stats_item_left_icon orange"></div>
        </div>
        <div class="profile_stats_item_right">
          <div class="profile_stats_item_right_name">9$</div>
          <div class="profile_stats_item_right_description">
            {{ $t("lessonPrice") }}
          </div>
        </div>
      </div>
    </div>
    <div class="mobile_profile">
      <div class="profile_bottom">
        <div class="bottom_input">
          <input type="number" class="input" placeholder="Ваш телефон" />
        </div>
        <div class="profile_bottom_description">
          {{ $t("bySending") }}
        </div>
        <div class="orange_btn">
          {{ $t("exchangeContacts") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpenedRating from "@/components/profile/OpenedRating.vue";

export default {
  name: "platformProfile",
  props: {
    item: {
      default: Object,
    },
  },
  components: { OpenedRating },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    opened() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style>
.hull_height {
  height: auto;
  transition: height 0.3s linear;
}
.no_height {
  overflow: hidden;
  height: 0;
  transition: height 0.3s linear;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
