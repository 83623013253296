<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import OutlineBtn from "@/components/mini-components/OutlineBtn.vue";
import PlatformProfile from "@/components/platform/PlatformProfile.vue";

export default {
  name: "platformCarousel",
  components: { PlatformProfile },
  data() {
    return {
      slider: [
        {
          id: 1,
          image: "/platform/1.png",
          name: "Айгуль",
          description:
            "Дипломированный специалист по английской филологии. Опыт преподавания - 5 лет.",
          video_link:
            "https://www.youtube.com/embed/HoHihTK4ZY0?si=1Gw5W326X3jogmNx",
        },
        {
          id: 2,
          image: "/platform/2.png",
          name: "Руслан",
          description:
            "Дипломированный специалист по английской филологии. Опыт преподавания - 5 лет.",
          video_link:
            "https://www.youtube.com/embed/1FcvmW06i_k?si=_5TVbqYKJ916cCg-",
        },
        {
          id: 3,
          image: "/platform/3.png",
          name: "Дарья",
          description:
            "Дипломированный специалист по английской филологии. Опыт преподавания - 5 лет.",
          video_link:
            "https://www.youtube.com/embed/QF7fr_HLU9s?si=I9VM2pbabSw51WNX",
        },
      ],
      isOpen1: false,
      item: {},
    };
  },
  methods: {
    toggleVideo(item) {
      this.isOpen1 = !this.isOpen1;
      if (this.isOpen1) {
        this.item = item;
      } else {
        this.item = {};
      }
    },
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation, Pagination],
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      scrollbar: {
        el: ".swiper-scrollbar",
      },
    });
  },
};
</script>

<template>
  <div class="swiper_parent pl-7 pr-7">
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in slider" :key="item.id">
          <div class="platform_card">
            <div class="platform_card_heading">
              <img
                class="teacher_card_img"
                :src="item.image"
                alt="Teacher"
                loading="lazy"
                :title="item.text"
                draggable="false"
              />
            </div>
            <div class="platform_card_body">
              <div class="platform_card_body_name">
                {{ item.name }}
              </div>
              <div class="platform_card_body_description">
                {{ $t("experience") }}
              </div>
              <div class="platform_stats">
                <div class="platform_stats_item">
                  <div class="profile_stats_item_left_icon red"></div>
                  <div class="platform_stats_item_number">4.8</div>
                  <div class="platform_stats_item_text">
                    {{ $t("provenCapability") }}
                  </div>
                </div>
                <div class="platform_stats_item">
                  <div class="profile_stats_item_left_icon yellow"></div>
                  <div class="platform_stats_item_number">4.1</div>
                  <div class="platform_stats_item_text">
                    {{ $t("strictness") }}
                  </div>
                </div>
                <div class="platform_stats_item">
                  <div class="profile_stats_item_left_icon green"></div>
                  <div class="platform_stats_item_number">5.0</div>
                  <div class="platform_stats_item_text">
                    {{ $t("punctuality") }}
                  </div>
                </div>
                <div class="platform_stats_item">
                  <div class="profile_stats_item_left_icon blue"></div>
                  <div class="platform_stats_item_number">320</div>
                  <div class="platform_stats_item_text">
                    {{ $t("overalLessons") }}
                  </div>
                </div>
                <div class="platform_stats_item">
                  <div class="profile_stats_item_left_icon orange"></div>
                  <div class="platform_stats_item_number">12$</div>
                  <div class="platform_stats_item_text">
                    {{ $t("lessonPrice") }}
                  </div>
                </div>
              </div>
              <div class="platform_btn">
                <!--                <outline-btn @click="toggleVideo(item)" name="Подробнее" />-->
                <div @click="toggleVideo(item)" class="outline_btn">
                  {{ $t("readMoreBtn") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>

      <div class="swiper-scrollbar"></div>
    </div>
    <v-dialog
      v-model="isOpen1"
      width="auto"
      overlay-color="rgba(0, 0, 0, 1)"
      class="position-relative"
    >
      <platform-profile :item="item">
        <img
          @click="isOpen1 = false"
          class="close_profile"
          src="@/assets/images/header/close.svg"
          alt="close"
          title="close"
          aria-label="close"
        />
      </platform-profile>
    </v-dialog>
  </div>
</template>

<style scoped>
.swiper-button-next,
.swiper-button-prev {
  background: transparent !important;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid transparent;
  color: var(--gray) !important;
}
.swiper_parent {
  overflow: hidden;
}
.swiper {
  overflow: visible;
  min-height: 810px;
}
.swiper-button-prev {
  position: absolute;
  left: -35px;
}

.teacher_card_img {
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
}
.swiper-button-next {
  position: absolute;
  right: -35px;
}

.swiper-button-next::after {
  position: absolute;
  top: 5%;
  left: 20%;
  width: 10px;
  height: 10px;
  padding: 10px;
  font-size: 20px;
}

.swiper-button-prev::after {
  position: absolute;
  top: 5%;
  left: 10%;
  color: var(--gray) !important;
  width: 10px;
  height: 10px;
  padding: 10px;
  font-size: 20px;
}
.swiper-pagination-bullet {
  width: 50px !important;
  height: 4px;
  border-radius: 3px;
  background: var(--orange100);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--orange400) !important;
}

.platform_card {
  box-shadow: 0px 0px 18.899999618530273px 0px #0000000a;
  background: var(--white);
  border-radius: 24px;
}
.platform_card_body {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 25px;
}

.platform_card_body_name {
  font-family: Raleway_Medium, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.platform_card_body_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.platform_stats {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.platform_stats_item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.platform_stats_item .profile_stats_item_left_icon {
  transform: translateY(5px) !important;
}

.platform_stats_item_number {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.platform_stats_item_text {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray3);
}
.platform_btn {
  z-index: 9;
  position: initial;
}
.platform_btn .outline_btn {
  text-align: center;
  width: 100%;
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--gray);
  z-index: 9;
  cursor: pointer;
}
.profile_card {
  position: relative;
}
.close_profile {
  width: 21px;
  height: 21px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.choose_teacher_parent_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.platform_bottom {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
