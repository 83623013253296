<template>
  <div class="container">
    <div class="work_parent" id="how_it_work">
      <div class="default_title">{{ $t("seeHowItWork") }}</div>
      <div class="work_body_parent">
        <div class="work_col" data-aos-delay="200" data-aos="fade-up">
          <div class="work_col_img">
            <img
              src="@/assets/images/work/work1.png"
              alt="work"
              draggable="false"
              title="work"
              class="work_img"
            />
            <img
              src="@/assets/images/work/player.svg"
              alt="player"
              draggable="false"
              title="player"
              aria-label="toggle"
              class="player_icon"
              @click.prevent="openVideo(1)"
            />
          </div>
          <div class="work_description">{{ $t("choosingATeacher") }}</div>
        </div>
        <div class="work_col" data-aos="fade-up">
          <div class="work_col_img">
            <img
              src="@/assets/images/work/work2.png"
              alt="work"
              draggable="false"
              title="work"
              class="work_img"
            />
            <img
              src="@/assets/images/work/player.svg"
              alt="player"
              draggable="false"
              aria-label="toggle"
              title="player"
              class="player_icon"
              @click.prevent="openVideo(2)"
            />
          </div>
          <div class="work_description">{{ $t("lessonRoutine") }}</div>
        </div>
      </div>
    </div>

    <v-dialog v-model="isOpen1" width="auto" overlay-color="rgba(0, 0, 0, 1)">
      <div class="video_card">
        <div class="video_card_heading">
          <div class="video_title">{{ $t("choosingATeacher") }}</div>
          <img
            @click="isOpen1 = false"
            class="pointer"
            src="@/assets/images/header/close.svg"
            alt="close"
            title="close"
            aria-label="close"
          />
        </div>
        <div class="video_card_body">
          <iframe
            v-if="isOpen1"
            width="1080"
            height="540"
            class="video_iframe"
            src="https://www.youtube.com/embed/vmYCj4oRCNQ?si=KNnASWHPRqeILegv"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="isOpen2" width="auto" overlay-color="rgba(0, 0, 0, 1)">
      <div class="video_card">
        <div class="video_card_heading">
          <div class="video_title">{{ $t("lessonRoutine") }}</div>
          <img
            @click="isOpen2 = false"
            class="pointer"
            src="@/assets/images/header/close.svg"
            alt="close"
            title="close"
            aria-label="close"
          />
        </div>
        <div class="video_card_body">
          <!--          <video src="@/assets/video/video2.mp4" autoplay controls></video>-->
          <iframe
            v-if="isOpen2"
            width="1080"
            height="540"
            class="video_iframe"
            src="https://www.youtube.com/embed/s3iffZPye-8?si=dDPdl-OFMajQ0thB"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "howItIsWork",
  components: {},
  data() {
    return {
      isOpen1: false,
      isOpen2: false,
    };
  },
  methods: {
    openVideo(video_number) {
      if (video_number === 1) {
        this.isOpen1 = !this.isOpen1;
      } else {
        this.isOpen2 = !this.isOpen2;
      }
    },
  },
};
</script>

<style scoped>
.work_body_parent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.work_parent {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.work_col {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.work_col .work_img {
  border-radius: 24px;
  width: 100%;
}

.work_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: var(--gray);
}

.work_col_img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_card {
  background: var(--white);
  padding: 40px;
}
.video_card_heading {
  display: flex;
  justify-content: space-between;
}
.video_title {
  font-family: Raleway_Medium, sans-serif, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: var(--gray);
  padding-bottom: 20px;
}
.video_card_body {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
}
.video_card_body video {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  max-width: 100vh;
}

@media screen and (max-width: 567px) {
  .video_card_body .video_iframe {
    height: 100% !important;
    min-height: 190px;
  }
}

@media (min-width: 0px) and (max-width: 567px) {
  .work_parent {
    gap: 32px;
  }
  .work_body_parent {
    display: flex !important;
    flex-direction: column !important;
    gap: 32px !important;
  }
  .video_card {
    padding: 20px;
  }
  .video_title {
    font-size: 20px;
    padding-bottom: 0;
  }
  .video_card_heading {
    align-items: center;
  }
}
@media (min-width: 568px) and (max-width: 1279px) {
  .work_parent {
    gap: 50px;
  }
  .work_body_parent {
    display: flex !important;
    flex-direction: column !important;
    gap: 50px !important;
  }

  .test_for_mobile {
    display: flex;
    margin-top: 50px;
  }
  .test_for_pc {
    display: none;
  }
  .test_right {
    transform: translate(0) !important;
  }
  .test_right img {
    max-width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    transform: translate(0) !important;
  }
}
</style>
