<template>
  <div class="hero">
    <div class="container">
      <div class="hero_section_row">
        <div class="hero_section_col">
          <div class="hero_main_text">
            {{ $t("socialPlatform") }}
          </div>
          <div class="hero_main_description">
            {{ $t("hereSuccess") }}
          </div>
          <div class="hero_buttons">
            <a href="https://lingua.community/login" class="bg_btn w-100">
              {{ $t("register") }}</a
            >
            <a href="https://lingua.community/search" class="outline_btn w-100">
              {{ $t("findATeacher") }}</a
            >
          </div>
        </div>
        <div class="hero_section_col_right">
          <img
            loading="lazy"
            class="hero_img"
            src="@/assets/images/header/main.webp"
            alt="main_img"
            title="skype study"
            draggable="false"
          />
          <div class="hero_right_texts">
            <div class="hero_right_text">
              {{ $t("magicStarts") }}
            </div>
            <div class="hero_right_address">
              {{ $t("heroAddress") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroComponent",
  components: {},
};
</script>

<style scoped>
.hero_section_row {
  display: grid;
  grid-template-columns: 2fr auto;
  gap: 33px;
}

.hero_section_col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
}

.hero_main_text {
  font-family: Raleway_Bold, sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: 0;
  text-align: left;
  color: var(--dark_gray);
}
.hero_main_description {
  font-family: Raleway_Medium, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0;
  text-align: left;
  color: var(--gray);
}

.hero_img {
  max-width: 464px;
  max-height: 463px;
  width: 100%;
  height: 100%;
}

.hero_buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
}
.hero_section_col_right {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.hero_right_texts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.hero_right_text {
  max-width: 471px;
  width: 100%;
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.hero_right_address {
  color: var(--gray3);
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}

@media screen and (max-width: 567px) {
  /*     hero section*/
  .hero_section_row {
    display: flex;
    flex-direction: column;
  }
  .hero_buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .hero_buttons button {
    width: 100%;
    text-align: center;
  }
  .hero_section_col {
    gap: 32px;
  }
  .hero_main_text {
    font-size: 36px;
    line-height: 46.8px;
  }
  .hero_main_description {
    font-size: 15px;
    line-height: 21px;
    text-align: left;
  }
  .hero_right_texts {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
@media (min-width: 568px) and (max-width: 1279px) {
  .hero_section_row {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 80px;
  }
  .hero_buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .hero_section_col_right {
    display: flex;
    justify-content: space-between;
    flex-direction: unset;
  }
  .hero_img {
    max-width: 322px;
  }
  .hero_section_col {
    gap: 32px;
  }
  .hero_main_text {
    font-size: 36px;
    line-height: 46.8px;
  }
  .hero_main_description {
    font-size: 15px;
    line-height: 21px;
    text-align: left;
  }
  .hero_right_texts {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
