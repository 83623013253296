<template>
  <div class="container">
    <div class="possibilities_parent">
      <div class="default_title">{{ $t("seeWhatInside") }}</div>
      <div class="possibilities">
        <div class="possibilities_heading">
          <div class="tabs">
            <v-tabs color="#FF982F">
              <v-tab> {{ $t("audio") }}</v-tab>
              <v-tab>{{ $t("test") }}</v-tab>
              <v-tab>{{ $t("dictionary") }}</v-tab>
              <v-tab class="mobile_hidden"> {{ $t("board") }}</v-tab>
            </v-tabs>
          </div>
        </div>
        <div class="possibilities_body">
          <a href="https://lingua.community/promo/instructor" target="_blank">
            <img src="@/assets/images/possible.png" alt="" />
          </a>
          <div class="d-flex justify-center">
            <a
              href="https://lingua.community/promo/instructor"
              target="_blank"
              class="orange_btn"
            >
              {{ $t("tryItYourself") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "viewPossibilities",
  components: {},
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
  },
};
</script>

<style>
.possibilities_parent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.possibilities {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.possibilities_heading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs {
  display: flex;
  border-bottom: 1px solid var(--ui_grey_30);
  background: var(--main_bg) !important  ;
}
.v-tabs-bar__content {
  background: var(--main_bg) !important  ;
}
.tab {
  display: flex;
  height: 44px;
  align-items: center;
  padding: 4px 24px;
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: all 0.2s linear;
}
.possibilities_body img {
  width: 100%;
}
.possibilities_body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}
.possibilities_body a {
  text-align: center;
}
.active_tab {
  border-bottom: 3px solid var(--orange600);
}

@media screen and (max-width: 567px) {
  .mobile_hidden {
    display: none;
  }
}

@media (min-width: 568px) and (max-width: 1279px) {
}
</style>
