import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/locales/en.ts";
import ru from "@/locales/ru.ts";
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "ru",
  legacy: false,
  fallbackLocale: "ru",
  messages: {
    en,
    ru,
  },
});
