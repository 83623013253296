<template>
  <div class="d-flex">
    <img
      class="logo_img"
      src="@/assets/images/logo.svg"
      alt="logo"
      title="logo"
    />
    <div class="logo_text">Lingua.community</div>
  </div>
</template>

<script>
export default {
  name: "logoComponent",
};
</script>

<style scoped>
.logo_img {
  width: 24px;
  height: 32px;
}

.logo_text {
  font-family: Raleway_SemiBold, sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 8px;
  text-decoration: none !important;
  color: var(--gray) !important;
}
</style>
