<template>
  <div class="container">
    <div class="choose_teacher_parent">
      <div class="choose_teacher_parent_heading">
        <div class="default_title">{{ $t("howToFind") }}</div>
        <div class="default_description">
          {{ $t("clearAssessment") }}
        </div>
      </div>
      <div class="choose_teacher_parent_body">
        <teacher-carousel />
      </div>
    </div>
  </div>
</template>

<script>
import TeacherCarousel from "@/components/teacher/TeacherCarousel.vue";

export default {
  name: "chooseTeacher",
  components: { TeacherCarousel },
};
</script>

<style>
.choose_teacher_parent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.choose_teacher_parent_heading {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
</style>
