<template>
  <div class="lang_parent">
    <div
      class="bg_lang"
      v-if="isCardVisible"
      @click="isCardVisible = false"
    ></div>
    <div
      class="active_lang"
      @click="toggleDropdown"
      :class="{ opened_lang: isCardVisible }"
    >
      <div class="current_lang">{{ activeLang }}</div>
      <img
        class="current_lang_flag"
        :src="`/lang/${activeLang.toLowerCase()}.svg`"
        :alt="activeLang"
        :title="activeLang"
      />
      <img
        class="down_lang"
        src="../../assets/images/down.svg"
        alt="down"
        title="down"
        aria-label="down"
      />
    </div>
    <Transition name="slide-fade">
      <div class="store_lang" v-if="isCardVisible" draggable="false">
        <div
          v-for="lang in supportedLanguages"
          :key="lang.code"
          @click="changeLocale(lang.code)"
          class="lang_items"
          v-show="activeLang !== lang.code"
        >
          <div class="lang_item">{{ lang.code }}</div>
          <img
            class="current_lang_flag"
            :src="`/lang/${lang.code}.svg`"
            :alt="lang.code"
            :title="lang.code"
          />
          <img
            style="visibility: hidden"
            class="down_lang"
            src="../../assets/images/down.svg"
            alt="down"
            title="down"
            aria-label="down"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "LangComponent",
  data() {
    return {
      isCardVisible: false,
    };
  },
  computed: {
    activeLang() {
      return this.$i18n.locale;
    },
    supportedLanguages() {
      return [
        { code: "en" },
        { code: "ru" },
        { code: "kg" },
        { code: "fr" },
        { code: "kz" },
        { code: "tr" },
      ];
    },
  },
  methods: {
    toggleDropdown() {
      this.isCardVisible = !this.isCardVisible;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.isCardVisible = false;
    },
  },
};
</script>

<style>
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.lang_parent {
  max-width: 100px;
  width: 100%;
}
.active_lang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  gap: 3px;
  padding: 8px 12px;
  border: 1.2px solid var(--ui_grey_30);
  height: 48px;
  cursor: pointer;
  max-width: 100px;
  width: 100%;
  transition: all 0.3s linear;
}
.opened_lang {
  border-radius: 12px 12px 0 0 !important;
  border-bottom: 1.2px solid transparent !important;
}
.current_lang {
  font-family: Manrope_Regular, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: var(--ui_grey_60);
  text-transform: uppercase;
}

.store_lang {
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 0 0 12px 12px;
  background: var(--main_bg);
  border-right: 1.2px solid var(--ui_grey_30);
  border-left: 1.2px solid var(--ui_grey_30);
  border-bottom: 1.2px solid var(--ui_grey_30);
  max-width: 100px;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}
.bg_lang {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.lang_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  width: 100%;
  padding: 8px 12px;
  transition: all 0.2s linear;
}
.lang_items:hover {
  background: var(--orange50);
}
.lang_item {
  font-family: Manrope_Regular, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: var(--ui_grey_60);
  text-transform: uppercase;
}
</style>
