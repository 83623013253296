<template>
  <div class="container">
    <div class="profile_parent">
      <div class="default_title">{{ $t("exampleProfile") }}</div>
      <ProfileCard />
    </div>
  </div>
</template>

<script>
import ProfileCard from "@/components/profile/ProfileCard.vue";

export default {
  name: "ProfileExample",
  components: { ProfileCard },
};
</script>
<style></style>
