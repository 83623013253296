<template>
  <div class="container">
    <div class="form_parent">
      <div class="form_card">
        <div class="form_card_left">
          <div
            class="form_card_left_title"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            {{ $t("startTeaching") }}
          </div>
          <div
            class="form_card_left_description"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            {{ $t("byRegister") }}
          </div>
          <div class="form_buttons" data-aos="fade-up" data-aos-delay="300">
            <a
              href="https://lingua.community/registration"
              class="orange_btn"
              >{{ $t("connectAndJoin") }}</a
            >
            <a href="https://t.me/leontevruslan" class="outline_orange_btn">{{
              $t("talkToUs")
            }}</a>
          </div>
        </div>
        <div class="form_card_right">
          <img
            class="form_image"
            src="@/assets/images/form/form.png"
            alt=""
            draggable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "formComponent",
};
</script>

<style scoped>
.form_card {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;
  padding: 80px 50px;
  border-radius: 24px;
  background: var(--orange50);
  position: relative;
}

.form_image {
  max-width: 391px;
  position: absolute;
  top: -20px;
}

.form_card_left_title {
  font-family: Raleway_SemiBold, sans-serif;
  font-size: 42px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
}

.form_card_left {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form_card_left_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.form_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /*display: flex;*/
  /*justify-content: flex-start;*/
  gap: 32px;
  max-width: 596px;
}

@media screen and (max-width: 567px) {
  .form_card {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    padding: 44px 16px;
  }
  .form_image {
    display: flex;
    position: initial;
    width: 100%;
    height: 100%;
  }
  .form_card_left_title {
    font-family: Raleway_Bold, sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: left;
  }
  .form_buttons {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }
}

@media (min-width: 568px) and (max-width: 1279px) {
  .form_card {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .form_image {
    display: flex;
    position: initial;
    width: 100%;
    height: 100%;
  }
  .form_card_left {
    max-width: 560px;
  }
  .form_card_left_title {
    font-family: Raleway_Bold, sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: left;
  }
  .form_buttons {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }
}
</style>
