<template>
  <div class="opened_rating">
    <div class="opened_rating_card">
      <div class="opened_rating_card_heading">
        <div class="rating_profile d-flex align-center">
          <img
            class="ava_gallery"
            src="@/assets/images/profile/1.png"
            alt="ava"
          />
          <div class="rating_heading_name">{{ $t("firstRatingName") }}</div>
        </div>
        <div class="rating_numbers">
          <img
            class="rating_star"
            src="@/assets/images/profile/star.svg"
            alt="star"
          />
          5.8
        </div>
        <div class="rating_get_lessons">{{ $t("firstRatingLessons") }}</div>
      </div>
      <div class="opened_rating_card_body">
        <div class="opened_rating_card_body_text">
          {{ $t("firstRatingDescription") }}
        </div>
      </div>
    </div>
    <div class="opened_rating_card">
      <div class="opened_rating_card_heading">
        <div class="rating_profile d-flex align-center">
          <img
            class="ava_gallery"
            src="@/assets/images/profile/2.png"
            alt="ava"
          />
          <div class="rating_heading_name">{{ $t("secondRatingName") }}</div>
        </div>
        <div class="rating_numbers">
          <img
            class="rating_star"
            src="@/assets/images/profile/star.svg"
            alt="star"
          />
          5.8
        </div>
        <div class="rating_get_lessons">{{ $t("secondRatingLessons") }}</div>
      </div>
      <div class="opened_rating_card_body">
        <div class="opened_rating_card_body_text">
          {{ $t("secondRatingDescription") }}
        </div>
      </div>
    </div>
    <div class="opened_rating_card">
      <div class="opened_rating_card_heading">
        <div class="rating_profile d-flex align-center">
          <img
            class="ava_gallery"
            src="@/assets/images/profile/3.png"
            alt="ava"
          />
          <div class="rating_heading_name">{{ $t("thirdRatingName") }}</div>
        </div>
        <div class="rating_numbers">
          <img
            class="rating_star"
            src="@/assets/images/profile/star.svg"
            alt="star"
          />
          5.8
        </div>
        <div class="rating_get_lessons">{{ $t("thirdRatingLessons") }}</div>
      </div>
      <div class="opened_rating_card_body">
        <div class="opened_rating_card_body_text">
          {{ $t("thirdRatingDescription") }}
        </div>
      </div>
    </div>
    <div class="opened_rating_card">
      <div class="opened_rating_card_heading">
        <div class="rating_profile d-flex align-center">
          <img
            class="ava_gallery"
            src="@/assets/images/profile/4.png"
            alt="ava"
          />
          <div class="rating_heading_name">{{ $t("fourRatingName") }}</div>
        </div>
        <div class="rating_numbers">
          <img
            class="rating_star"
            src="@/assets/images/profile/star.svg"
            alt="star"
          />
          5.8
        </div>
        <div class="rating_get_lessons">{{ $t("fourRatingLessons") }}</div>
      </div>
      <div class="opened_rating_card_body">
        <div class="opened_rating_card_body_text">
          {{ $t("fourRatingDescription") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "openedRating",
};
</script>

<style scoped></style>
