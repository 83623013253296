<template>
  <div class="gallery_parent">
    <div class="container gallery">
      <div class="gallery_heading">
        <div class="gallery_heading_text">
          {{ $t("dontRisk") }}
        </div>
        <div class="gallery_heading_description">
          {{ $t("weOffer") }}
        </div>
      </div>
      <div class="gallery_body">
        <div class="left_gallery">
          <div
            data-aos-delay="100"
            data-aos="zoom-in"
            class="right_gallery_box"
          >
            <div class="right_gallery_box_heading">
              <div class="top_text">
                {{ $t("criteria") }}
              </div>
              <div class="gallery_text">
                {{ $t("assessTeachers") }}
              </div>
            </div>
            <div class="right_gallery_box_footer">
              <img
                class="ava_gallery"
                src="@/assets/images/gallery/ava.svg"
                alt="ava"
              />
              <div class="gallery_rating_box">
                <div class="gallery_rating">4.8</div>
                <img
                  class="start_gallery"
                  src="@/assets/images/gallery/star.svg"
                  alt="star"
                />
              </div>

              <img
                class="bottom_frames"
                src="@/assets/images/gallery/right_bottom_frames.svg"
                alt="bottom frames"
              />
            </div>
          </div>
          <img
            data-aos-delay="100"
            data-aos="zoom-in"
            class="first_img"
            src="@/assets/images/gallery/first.png"
            alt="first"
          />
          <div class="left_bottom" data-aos="zoom-in">
            <div class="gallery_text">
              {{ $t("supportedByLocal") }}
            </div>
          </div>
        </div>
        <div class="center_gallery">
          <div class="center_gallery_top">
            <img
              class="center_top_img"
              src="@/assets/images/gallery/center_top.png"
              alt="center_top"
              data-aos-delay="200"
              data-aos="zoom-in"
            />
            <div
              class="center_gallery_top_box mobile_hidden"
              data-aos-delay="100"
              data-aos="zoom-in"
            >
              <div class="top_text">
                0
                <span>₽</span>
              </div>
              <div class="gallery_text">
                {{ $t("freeForStudents") }}
              </div>
            </div>
          </div>

          <div class="desktop_hidden">
            <div
              class="center_gallery_top_box"
              data-aos-delay="100"
              data-aos="zoom-in"
            >
              <div class="top_text">
                0
                <span>₽</span>
              </div>
              <div class="gallery_text">
                {{ $t("freeForStudents") }}
              </div>
            </div>
            <div
              class="center_gallery_top_box_blue"
              data-aos-delay="100"
              data-aos="zoom-in"
            >
              <div class="top_text">100%</div>
              <div class="gallery_text">
                {{ $t("realTestimonials") }}
              </div>
            </div>
          </div>
          <div class="center_gallery_bottom">
            <div
              class="center_gallery_top_box_blue mobile_hidden"
              data-aos-delay="100"
              data-aos="zoom-in"
            >
              <div class="top_text">100%</div>
              <div class="gallery_text">
                {{ $t("realTestimonials") }}
              </div>
            </div>
            <img
              class="center_top_img"
              src="@/assets/images/gallery/center_bottom.png"
              alt="center_top"
              data-aos-delay="200"
              data-aos="zoom-in"
            />
          </div>
        </div>
        <div class="right_gallery">
          <div class="right_gallery_box" data-aos="zoom-in">
            <div class="right_gallery_box_heading">
              <div class="top_text">{{ $t("criteria") }}</div>
              <div class="gallery_text">
                {{ $t("assessTeachers") }}
              </div>
            </div>
            <div class="right_gallery_box_footer">
              <img
                class="ava_gallery"
                src="@/assets/images/gallery/ava.svg"
                alt="ava"
              />
              <div class="gallery_rating_box">
                <div class="gallery_rating">4.8</div>
                <img
                  class="start_gallery"
                  src="@/assets/images/gallery/star.svg"
                  alt="star"
                />
              </div>

              <img
                class="bottom_frames"
                src="@/assets/images/gallery/right_bottom_frames.svg"
                alt="bottom frames"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GallerySection",
};
</script>

<style scoped>
.gallery_heading {
  display: flex;
  flex-direction: column;
  gap: 37px;
  text-align: center;
}

.gallery_heading_text {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--gray);
}
.gallery_heading_description {
  font-family: Raleway_Medium, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
}
.gallery {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.gallery_body {
  display: flex;
  justify-content: flex-start;
  /*display: grid;*/
  /*grid-template-columns: 1fr 1fr 1fr;*/
  /*grid-template-rows: 1fr;*/
  gap: 50px;
}

.left_gallery {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 50px;
}
.first_img {
  max-width: 263.27px;
  max-height: 263.27px;
  width: 100%;
  height: 100%;
}

.left_bottom {
  background: var(--orange_light);
  max-width: 263.27px;
  width: 100%;
  border-radius: 24px;
  padding: 20px;
}
.gallery_text {
  font-family: Manrope_Regular, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.center_gallery {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.center_gallery_top,
.center_gallery_bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 50px;
}
.center_top_img {
  max-width: 367px;
  max-height: 263.47px;
  height: 100%;
  width: 100%;
}

.center_gallery_top_box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 157.94px;
  min-height: 157.94px;
  max-height: 157.94px;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  background: var(--orange_light);
  padding: 20px;
  margin-bottom: 30px;
}
.center_gallery_top_box_blue {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 157.94px;
  max-height: 201.26px;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  background: var(--blue_100);
  padding: 20px;
  margin-bottom: 30px;
}
.right_gallery {
  display: flex;
  align-items: center;
}
.right_gallery_box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 262px;
  max-height: 262px;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  background: var(--blue_100);
  padding: 20px;
  text-align: left !important;
}
.right_gallery_box_heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.right_gallery_box .top_text {
  text-align: left !important;
  white-space: nowrap;
}
.gallery_rating_box {
  display: flex;
  gap: 4px;
}
.right_gallery_box_footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.top_text {
  display: flex;
  align-items: flex-end;
  color: var(--gray);
  justify-content: flex-start;
  font-family: Raleway_Medium, sans-serif;
  font-size: 35px;
  font-weight: 500;
  /*line-height: 49px;*/
  letter-spacing: 0em;
  text-align: center;
}
.top_text div {
}
.top_text span {
  font-family: Raleway_Medium, sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
}

.gallery_rating {
  font-family: Manrope_Regular, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  color: var(--blue_600);
}
.desktop_hidden {
  display: none;
}
.mobile_hidden {
  display: flex;
}
@media screen and (max-width: 567px) {
  .mobile_hidden {
    display: none !important;
  }
  .desktop_hidden {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  /*   gallery*/
  .gallery_heading_text {
    font-size: 15px;
    line-height: 21px;
    text-align: left;
  }
  .gallery_heading_description {
    font-size: 28px;
    line-height: 39px;
    text-align: left;
  }
  .gallery_body {
    gap: 20px;
  }
  .left_gallery {
    display: flex;
    flex-direction: column-reverse;
  }
  .left_gallery .right_gallery_box {
    display: flex;
  }
  .center_gallery_top {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
  }
  .center_gallery_bottom {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  .right_gallery {
    display: none;
  }
  .center_top_img {
    /*max-width: 142px;*/
    /*max-height: 108.58px;*/
  }
  .center_gallery_top_box {
    margin-right: auto;
    margin-bottom: 0;
  }
  .center_gallery_top_box_blue {
    margin-left: auto;
    margin-bottom: 0;
  }
  .center_gallery_top_box,
  .center_gallery_top_box_blue {
    max-width: 100px;
    /*max-height: 63px;*/
    min-height: 63px;
    border-radius: 9px;
    padding: 4px;
  }
  .center_gallery_top_box {
    max-width: 85px;
  }
  .gallery_text {
    font-size: 11px;
    line-height: 15px;
  }
  .top_text {
    font-size: 14px;
  }
  .top_text span {
    font-size: 14px;
    line-height: normal;
  }

  .first_img {
    /*max-width: 102px;*/
    /*max-height: 108px;*/
    width: 100%;
    height: auto;
    min-width: 102px;
  }
  .left_bottom {
    /*max-width: 102px;*/
    border-radius: 9px;
    padding: 4px;
  }
  .left_gallery {
    gap: 20px;
  }
  .left_gallery .right_gallery_box {
    /*max-width: 102px;*/
    max-height: 137px;
    border-radius: 9px;
    padding: 4px;
  }
  .gallery_rating {
    font-size: 8px;
  }

  .start_gallery {
    width: 6px;
  }
  .bottom_frames {
    width: 37px;
  }
}

@media (min-width: 568px) and (max-width: 1279px) {
  .gallery_heading_text {
    font-size: 15px;
    line-height: 21px;
    text-align: left;
  }
  .gallery_heading_description {
    font-size: 28px;
    line-height: 39px;
    text-align: left;
  }
  .gallery_body {
    gap: 20px;
  }
  .left_gallery {
    display: flex;
    flex-direction: column;
  }
  .left_gallery .right_gallery_box {
    display: none;
  }
  .center_gallery_top {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
  }
  .center_gallery_bottom {
    gap: 20px;
    display: flex;
  }
  .right_gallery {
    display: flex;
  }
  .center_top_img {
    max-width: 204px;
    max-height: 146px;
  }
  .center_gallery_top_box {
    margin-bottom: 20px;
  }
  .center_gallery_top_box_blue {
    margin-bottom: 20px;
  }
  .center_gallery_top_box,
  .center_gallery_top_box_blue {
    max-width: 87px;
    min-width: 87px;
    max-height: 112px;
    min-height: 112px;
    border-radius: 9px;
    padding: 8px;
  }
  .center_gallery_top_box {
    min-height: 87px;
    max-height: 87px;
  }
  .gallery_text {
    font-size: 7px;
    line-height: 9px;
  }
  .top_text {
    font-size: 14px;
  }
  .top_text span {
    font-size: 14px;
    line-height: normal;
  }

  .first_img {
    max-width: 146px;
    max-height: 146px;
  }
  .left_bottom {
    max-width: 146px;
    border-radius: 9px;
    padding: 4px;
  }
  .left_gallery {
    gap: 20px;
  }
  .right_gallery_box {
    max-width: 145px;
    max-height: 145px;
    border-radius: 9px;
    padding: 8px;
  }
  .gallery_rating {
    font-size: 8px;
  }

  .start_gallery {
    width: 6px;
  }
  .bottom_frames {
    width: 37px;
  }
}
</style>
