<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "TestSwiper",
  methods: {
    initSwiper() {
      new Swiper(this.$refs.swiper, {
        // configure Swiper to use modules
        modules: [Navigation, Pagination],
        // Optional parameters
        loop: true,

        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });
    },
  },
  mounted() {
    this.initSwiper();
  },
  watch: {
    "$i18n.locale"() {
      this.initSwiper();
    },
  },
};
</script>

<template>
  <!-- Slider main container -->
  <div ref="swiper" class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide">
        <div class="d-flex flex-column">
          <div class="test_left d-flex flex-column test_swiper">
            <div class="test_left_item">
              <div class="left_number">1</div>
            </div>
            <div class="test_right_item p-0 pb-0">
              <div class="test_title">{{ $t("howToChoose") }}</div>
              <div class="test_descriptions">
                <div class="test_description">
                  {{ $t("findYourIdealTeacher") }}
                </div>
                <div class="test_description">
                  {{ $t("findYourIdealTeacher2") }}
                </div>
              </div>
            </div>
          </div>
          <div class="test_right" style="transform: translate(-20px, 0)">
            <img
              src="@/assets/images/rate/rate1.png"
              alt=""
              draggable="false"
            />
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="d-flex flex-column">
          <div class="test_left d-flex flex-column test_swiper">
            <div class="test_left_item">
              <div class="left_number">2</div>
            </div>
            <div class="test_right_item p-0 pb-0">
              <div class="test_title">
                {{ $t("ourQualityControl") }}
              </div>
              <div class="test_descriptions">
                <div class="test_description">
                  {{ $t("theLessonsAreConducted") }}
                </div>
                <div class="test_description">
                  {{ $t("inAddition") }}
                </div>
              </div>
            </div>
          </div>
          <div class="test_right" style="transform: translate(-20px, 0)">
            <img
              src="@/assets/images/rate/rate1.png"
              alt=""
              draggable="false"
            />
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="d-flex flex-column">
          <div class="test_left d-flex flex-column test_swiper">
            <div class="test_left_item">
              <div class="left_number">3</div>
            </div>
            <div class="test_right_item p-0 pb-0">
              <div class="test_title">
                {{ $t("testimonialsAfterLesson") }}
              </div>
              <div class="test_descriptions">
                <div class="test_description">
                  {{ $t("byTheEndOf") }}
                </div>
              </div>
            </div>
          </div>
          <div class="test_right" style="transform: translate(-20px, 0)">
            <img
              src="@/assets/images/rate/rate1.png"
              alt=""
              draggable="false"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>

    <!-- If we need navigation buttons -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>

    <!-- If we need scrollbar -->
    <div class="swiper-scrollbar"></div>
  </div>
</template>

<style scoped>
.swiper-button-next,
.swiper-button-prev {
  background: transparent !important;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid transparent;
  color: var(--gray) !important;
  position: absolute;
  top: 22px;
  right: 0;
}
.swiper-button-prev {
  position: absolute !important;
  right: 40px !important;
  left: auto !important;
}

.swiper-button-next::after {
  position: absolute;
  top: 5%;
  left: 20%;
  width: 10px;
  height: 10px;
  padding: 10px;
  font-size: 20px;
}

.swiper-button-prev::after {
  position: absolute;
  top: 5%;
  left: 10%;
  color: var(--gray) !important;
  width: 10px;
  height: 10px;
  padding: 10px;
  font-size: 20px;
}

.test_parent {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.test_body_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.test_right img {
  max-width: 568px;
  transform: translateY(-10px);
}
.test_left {
  display: flex;
  gap: 50px;
}
.test_right_item {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 150px;
}
.test_descriptions {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.test_title {
  font-family: Raleway_Medium, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.test_description {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.left_number {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background: var(--blue_100);
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.left_border {
  height: 100%;
  border-right: 3px dashed var(--blue_100);
  width: 52%;
}

.test_swiper {
  gap: 24px !important;
}

@media (min-width: 0px) and (max-width: 567px) {
  .test_for_mobile {
    overflow: hidden;
  }
  .test_right {
    transform: translate(0) !important;
  }
  .test_right img {
    max-width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    transform: translate(0) !important;
  }
}
@media (min-width: 568px) and (max-width: 1279px) {
  .test_for_mobile {
    overflow: hidden;
  }
}
</style>
