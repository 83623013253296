<template>
  <div class="container">
    <div class="guarantee_parent" id="guarantee">
      <div class="default_title">
        {{ $t("guaranteedBenefits") }}
      </div>
      <div class="guarantee_row">
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          class="guarantee_card guarantee_left"
        >
          <div class="guarantee_card_heading">
            <img src="@/assets/images/guarantee/1.svg" alt="" />
            <div class="guarantee_card_heading_title">
              {{ $t("toTheStudents") }}
            </div>
          </div>
          <div class="guarantee_card_body">
            <div class="guarantee_card_body_items">
              <div class="guarantee_card_body_item">
                • {{ $t("realRating") }}
              </div>
              <div class="guarantee_card_body_item">• {{ $t("riskFree") }}</div>
              <div class="guarantee_card_body_item">
                • {{ $t("studyMotivated") }}
              </div>
              <div class="guarantee_card_body_item">
                • {{ $t("workWithout") }}
              </div>
              <div class="guarantee_card_body_item">
                • {{ $t("noWaitList") }}
              </div>
              <div class="guarantee_card_body_item">
                • {{ $t("easyLearn") }}
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="guarantee_card guarantee_right">
          <div class="guarantee_card_heading">
            <img src="@/assets/images/guarantee/2.svg" alt="" />
            <div class="guarantee_card_heading_title">
              {{ $t("toTheTeacher") }}
            </div>
          </div>
          <div class="guarantee_card_body">
            <div class="guarantee_card_body_items">
              <div class="guarantee_card_body_item">
                • {{ $t("anOpportunity") }}
              </div>
              <div class="guarantee_card_body_item">
                • {{ $t("avoidEmotional") }}
              </div>
              <div class="guarantee_card_body_item">
                • {{ $t("theOpportunityToTeach") }}
              </div>
              <div class="guarantee_card_body_item">
                • {{ $t("increaseCost") }}
              </div>
              <div class="guarantee_card_body_item">
                • {{ $t("increaseYourIncome") }}
              </div>
              <div class="guarantee_card_body_item">
                • {{ $t("workWithoutInter") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "guaranteeComponent",
};
</script>

<style scoped>
.guarantee_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.guarantee_parent {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.guarantee_card {
  padding: 30px 50px;
  border-radius: 20px;
  gap: 12px;
  min-height: 700px;
}
.guarantee_left {
  background: var(--orange50);
}
.guarantee_right {
  background: var(--blue_light);
}

.guarantee_card_heading {
  display: flex;
  align-items: center;
  gap: 24px;
}

.guarantee_card_heading_title {
  font-family: Raleway_Medium, sans-serif;
  font-size: 34px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.guarantee_card_body_items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.guarantee_card_body_item {
  font-family: Manrope_Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

@media screen and (max-width: 567px) {
  .guarantee_row {
    display: flex;
    flex-direction: column;
  }
  .guarantee_card {
    padding: 30px 24px;
    border-radius: 20px;
    gap: 12px;
    min-height: auto;
  }
  .guarantee_card_heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .guarantee_card_heading_title {
    font-family: Raleway_Medium, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@media (min-width: 568px) and (max-width: 1279px) {
  .guarantee_row {
    display: flex;
    flex-direction: column;
  }
  .guarantee_card {
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    border-radius: 20px;
    gap: 12px;
    min-height: auto;
  }
}
</style>
